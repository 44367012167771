.at-wrapper-one {
  // background-image: url("/assets/images/bg.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-color: #020410;
  cursor: pointer;
}

.at-header-one {
  background-color: white;
  border-bottom: 1px solid #dededea6;
  height: auto;
  padding-bottom: 15px;
}

.adsense-one.ad200-200-one {
  width: 200px;
  height: 400px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  border-radius: 8px;
  background: linear-gradient(45deg, #FF5093, #9A37E7);
  color: white;
  letter-spacing: 0.5px;
  margin-bottom: 20px;
  position: relative;

  .adsense-container-one {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .stardust-ads-container-one {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    font-size: 14px;
    align-items: center;
    font-family: var(--primary-font);
    text-align: center;
    padding: 0px 10px;
  }
}

.sdbr {
  background-color: #3A4065 !important;
}

.sdbr-mn-one {
  padding-left: 0;
  list-style: none;
}

.sdbr-mn-one a {
  font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 400;
  display: inline-block;
}

.stbxcnt {
  .sdb {
    flex: 0 0 225px;
  }
}

.principal-navigation-one :global {
  padding: 10px 15px;

  .item {
    margin-bottom: 10px;
    padding: 5px 10px;
    transition: 0.5s background-color;

    &:hover {
      background-color: #191D32;
      border-radius: 5px;
    }

    a {
      display: flex;
      align-items: center;

      &.active {
        span {
          color: #E7F3F3;
        }
        i {
          color: #E7F3F3;
        }
      }

      i {
        font-size: 20px;
        color: #3A4065;
        margin-right: 20px;
        position: relative;
        top: -1px;
      }

      span {
        font-size: 13px;
        font-family: var(--primary-font);
        font-weight: 600;
        letter-spacing: 0.2px;
       // text-transform: uppercase;
        color: #757277;
        transition: 0.5s color;
        -webkit-font-smoothing: antialiased;
      }
    }
  }
}

.secondary-navigation-one {
  border-top: 1px solid #3A4065;
}


@import url('https://fonts.googleapis.com/css?family=Lato');

.rc-backdrop {
  position: fixed;
  top:0;
  left:0;
  bottom:0;
  right:0;
  z-index:80;
  &.invert {
    background: rgba(1,1,1,.7)
  }
}

.range-picker-input {
  display: inline-flex;
  border: 1px solid $divider-color;
  width: 300px;
  * {
    box-sizing: border-box;
  }
  &__icon {
    display: inline-flex;
    align-items: center;
  }
  &__start, &__end {
    display: inline-flex;
    flex: 1;
    .picker-input.range {
      input {
        width: 100%;
        border: none;
      }
    }
  }
}

.picker-input {
  display: inline-block;
  position: relative;
  &__icon {
    position:absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
    @include inline-center
  }
  &__text {
    padding: 10px;
    border: 1px solid $divider-color;
    outline: none;
    font-size: $base-font-size * 1.4;
    &:disabled {
      background: $divider-color;
    }
  }
  &__clear {
    position:absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    cursor: pointer;
  }
}

.picker {
  display: inline-block;
  &__container {
    position: absolute;
    z-index:100;
    &.portal {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
    &__include-time {
      border: 1px solid $divider-color;
      .calendar__item,
      .time__container { 
        border: none;
      }
    }
    &__tab {
      & button {
        padding: 5px 10px;
        outline: none;
        display: inline-flex;
        align-items: center;
        background: none;
        border:none;
        border-bottom: 2px solid $divider-color;
        &.active {
          color: $primary-color-dark;
          border-bottom: 2px solid $primary-color-dark;
        }
        &:first-child {
          border-right: none;
        }
        svg {
          margin-right: 5px;
        }
      }
      margin: 10px 0;
    }
  }
}


.time__container {
  display: inline-flex;
  align-items: center;
  border: 1px solid $divider-color;
  padding: 15px;
  background: white;
  font-family: 'Lato';
  font-size: $base-font-size;
  &__div {
    margin: 0 10px;
  }
  &__type {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
  }
}

.time-input {
  display: inline-block;
  width: 40px;
  overflow: hidden;
  &__up, &__down {
    border: 1px solid $divider-color;
    button {
      outline: none;
      width: 100%;
      cursor: pointer;
      border: none;
    }
  }

  &__text {
    width: 100%;
    border-left: 1px solid $divider-color;
    border-right: 1px solid $divider-color;
    box-sizing: border-box;
    input {
      width: 100%;
      box-sizing: border-box;
      border: none;
      font-size: 15px;
      padding: 5px;
      text-align: center;
      outline: none;
    }
  }

}

.calendar{
  display:inline-block;
  background: white;
  font-size: $base-font-size;
  *, *:before, *:after {
    box-sizing: border-box;
  }

  &__container {
    width: 270px;
    font-family: 'Roboto', sans-serif;
    display:none;
  }

  &__list {
    display:table;
  }

  &__item {
    display: table-cell;
    border: 1px solid lighten($divider-color,3%);
    &:not(:first-child) {
      border-left: none !important;
    }
  }

  &--show {
    display:inline-block;
  }

  &__head {
    position:relative;
    background: $primary-color;
    padding: 10px 6px;
    &--title {
      font-size: $title-font-size;
      color: white;
      text-align: center;
      margin: 4px;
    }
    &--button{
      outline: none;
      border: none;
      cursor: pointer;
      background: none;
      font-size: 20px;
      svg {
        fill: white;
      }
    }
    &--prev, &--next {
      position: absolute;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
    }
    &--prev {
      left:0;
    }
    &--next {
      right:0;
    }
  }

  &__panel {
    &--show {
      display: block !important;
    }

    &--today {
      background: $primary-color-light;
      padding: 5px;
      display:none;
      h2 {
        margin: 0;
        cursor: pointer;
        font-size: $base-font-size;
        text-align: center;
      }
    }
  }

  &__body {
    &--table{
      width: 100%;
      table-layout:fixed;
      text-align: center;
      border-spacing: none;
      border-collapse: collapse;
      th {
        height: 30px;
        vertical-align: middle;
        color: $primary-text-color;
      }
    }
  }

  &__day {
    vertical-align: top;
    padding-top:5px;
    height: 40px;
    &:hover:not(&--disabled) {
      background: $primary-color-light;
      cursor: pointer;
    }
    cursor:pointer;
    &--0 { color:red; }
    &--6 { color:blue; }
    &--today{ background: $today-bg-color; }
    &--disabled { color: #ddd; cursor: initial}
    &--start, &--end, &--selected {
      background: $primary-color;
      color: $primary-color-text;
      &:hover {
        background: $primary-color;
      }
    }
    &--range { background: lighten($primary-color-light,10%); }
    &--text{
      display: block;
      font-size: 10px;
    }
  }

  &__year, &__month {
    height: 55px;
    vertical-align: middle;
    &:hover {
      background: $primary-color-light;
      cursor: pointer;
    }
  }
}

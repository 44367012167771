@media (max-width: 1680px) {
  .at-vd-infoandcomment {
    padding-right: 300px;
  }
  .at-commentsarea {
    padding: 25px 300px 25px 0;
  }
  .at-sortarea {
    // width: 100%;
    display: flex;
    // position: static;
    // padding: 20px 0 10px;
    .at-themefrom {
      margin-left: auto;
    }
  }
}
@media (max-width: 1660px) {
  .at-analytics {
    padding: 20px 15px;
  }
  .at-vd-infoandcomment {
    padding-right: 240px;
  }
  .at-slidercontainer .carousel-control-next {
    right: 0;
  }
  .at-slidercontainer .carousel-control-prev {
    left: 0;
  }
  .at-commentsarea {
    padding-right: 240px;
  }
}
@media (max-width: 1600px) {
  html {
    font-size: 50% !important;
  }
  .at-videosslider .carousel-item .at-videobox {
    width: 25%;
  }
  .at-videosslider .at-videoslist .at-video {
    width: 298px;
  }
  .at-content-area {
    padding: 20px 10rem;
  }
  .at-slidercontainer {
    margin: 0 auto;
    padding: 0 20px;
    /* width: calc(100% - 200px); */
    width: 100%;
    .at-viewvideobox {
      img {
        margin: 0 auto;
      }
    }
  }
  .at-videoplay video {
    /* height: 516px;
        max-height: 516px; */
  }
}
@media (max-width: 1440px) {
  html {
    font-size: 50% !important;
  }
  .at-wrapper {
    min-height: 100%;
  }
  .at-loginarea {
    height: 100%;
  }
  .at-recommendedinfo .at-infobtn {
    min-width: 250px;
  }
  .at-bannervideobox.video-channel-box {
    margin: 0 20px;
    width: calc(100% - 640px);
  }
  .at-bannervideobox {
    width: calc(100% - 570px);
  }
  .at-slidercontainer {
    margin: 0 auto;
    padding: 0 30px;
    /* width: calc(100% - 160px); */
    width: 100%;
  }
  .at-video {
    // height: 84px;
  }
  .at-slidercontainer .at-viewvideobox {
    width: 345px;
    height: 180px;
    img {
      margin: 0 auto;
    }
  }
  .at-yourvideos .at-content .at-videobox .at-videocontent .at-description p {
    width: 100%;
  }
  .at-vd-infoandcomment {
    padding-right: 100px;
  }
  .at-commentsarea {
    padding: 25px 200px 25px 0;
  }
  .at-channel-banner .at-slidercontainer .at-viewvideobox {
    // width: 100%;
    // height: 100%;
    img {
      margin: 0 auto;
    }
  }
  .at-channel-banner .at-banner-area .at-addbox {
    width: 300px;
  }
  .at-tabcontent .at-content {
    margin-bottom: 40px;
  }
  .at-analytics {
    padding: 20px 15px;
  }
  .at-dropdown .btn {
    font-size: 2.6rem;
  }
  .at-bannervideobox {
    .at-slidercontainer {
      .at-viewvideobox {
        width: 100%;
      }
    }
  }
  .video-channel-box {
    .at-slidercontainer {
      .at-viewvideobox {
        // width: 345px;
        width: 100%;
        // margin: 0 auto;
      }
    }
  }
  .at-videoplay video {
    /* height: 375px;
        max-height: 375px; */
  }
}
@media (max-width: 1366px) {
  .at-vd-infoandcomment {
    padding-right: 100px;
  }
  .at-commentsarea {
    padding: 25px 100px 25px 0;
  }
  .at-yourvideos .at-content .at-videobox .at-actions {
    margin-bottom: 25px;
  }
  /*     .at-channel-banner .at-bannervideobox{
        width: calc(100% - 600px);
    } */
  .at-statisticsarea {
    flex-wrap: wrap;
  }
  .at-statisticbox {
    width: 33.33%;
    margin-bottom: 20px;
  }
}
@media (max-width: 1360px) {
  .at-themeform .at-btn {
    padding: 10px 45px;
  }
  .at-addbox img {
    object-fit: inherit;
  }
  .at-yourvideos .at-content .at-videobox .at-video {
    // height: 150px;
  }
  .at-vd-infoandcomment {
    padding-right: 0;
  }
  .at-commentsarea {
    padding: 25px 0;
  }
  .at-videodetails .at-videocontent .at-likeandrating-area {
    margin-bottom: 0px;
  }
  .at-settings .at-contentbox .at-leftbox {
    padding-right: 50px;
  }
  .at-navtabs {
    width: 300px;
  }
  .at-settings .at-contentbox .at-rightbox {
    width: calc(100% - 350px);
  }
  .linechat {
    // padding: 60px 20px 0;
  }
}
@media (max-width: 1359px) {
  .at-videoslist .at-video {
    width: 100%;
  }
  .at-yourvideos .at-videoslist .at-video {
    width: 282px;
  }
  .at-videodetails .at-videocontent .at-likeandrating-area {
    position: static;
  }
  .at-slidercontainer {
    /* width: calc(100% - 160px); */
    width: 100%;
  }
}
@media (max-width: 1280px) {
  .at-bannervideobox {
    width: calc(100% - 519px);
  }
  .at-banner-area .at-addbox {
    width: 200px;
  }
  .at-slidercontainer .at-viewvideobox {
    width: 260px;
    // height: 100%;
  }
  // .at-video{
  //     height: 100%;
  // }
  .at-searchbox {
    width: 400px;
  }
  /* .at-channelvideo .at-video{
        height: 100%;
    } */
}
@media (max-width: 1230px) {
  .at-videodetails .at-videocontent .at-likeandrating-area .at-likeunlikestatus button span {
    font-size: 15px !important;
  }
  .at-videodetails .at-videocontent .at-likeandrating-area .at-likeunlikestatus button i  {
    width: 20px;
  }
}
@media (max-width: 1199px) {
  .at-recommendedinfo {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .at-recommendedinfo {
    margin-bottom: 10px;
  }

  .at-bannervideobox {
    width: calc(100% - 270px);
    margin: 0 20px 0 0;
  }
  .at-slidercontainer .at-viewvideobox {
    width: 100%;
  }
  .at-rightarea .at-beacreaterbtn,
  .at-rightarea .at-loginbtn {
    margin: 0;
  }
  .at-rightarea .at-beacreaterbtn span {
    display: none;
  }
  .at-rightarea .at-beacreaterbtn i {
    margin: 0;
    display: block;
  }
  // .at-ratingbox span{display: none;}
  .at-yourvideos .at-content .at-videobox {
    flex-direction: column;
  }
  .at-yourvideos .at-content .at-videobox .at-video {
    // height: 100%;
    margin: 0 0 10px;
  }
  .at-yourvideos .at-content .at-videobox .at-videocontent {
    width: 100%;
  }
  .at-videodetails {
    flex-direction: column;
  }
  .at-videodetails .at-content {
    width: 100%;
    margin-bottom: 20px;
  }
  .at-sidebar {
    margin: 0;
    width: 100%;
  }
  .at-sidebar .at-videolist {
    display: grid;
    float: left;
    grid-gap: 20px;
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  }
  .at-channel-banner .at-bannervideobox {
    width: calc(100% - 300px);
    margin: 0 auto;
  }
  .at-channel-banner .at-banner-area {
    align-items: start;
  }
  .at-channel-banner .at-addarea {
    width: 100%;
  }
  .at-channel-banner .at-addbox {
    margin: 0 auto;
  }
  .at-content-area {
    padding: 20px 5rem;
  }
  .at-uploadform .at-leftbox {
    margin-right: 40px;
  }
  .at-uploadform .at-rightbox {
    width: calc(100% - 140px);
  }
  .at-earningbox {
    margin: 20px 0;
  }
  .at-earningbox::after {
    display: none;
  }
  .at-chartbox .linechat {
    margin-bottom: 40px;
  }
}
@media (max-width: 1024px) {
  .at-videoslist {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}

@media(max-width: 1110px){
  .at-navarea .at-searcharea {
    padding: 0 15px;
    width: 100%;
    position: absolute;
    margin: 0px;
    height: 100%;
    top: 0px;
    display: none;
    align-items: center;

    form {
      display: block;

      button {
        position: absolute;
        z-index: 100;
        background: transparent;
        color: white;

        * {
          color: white;
        }
      }

      .form-control {
        height: 45px !important;
        background: #020410;
        padding: 10px 50px 10px 35px;
        border: 1px solid #191D32;
      }

      .at-inputwithbtn {
        display: block;
      }
    }
  }
  .at-navarea .at-searchbtn {
    display: block;
    margin-left: auto;
  }
  .at-navarea .at-searchbox .at-crossbtn {
    display: block;
  }

  .headerwithlogout .at-navarea .at-searchbtn {
    display: none;
    margin-left: 0;
  }
  .headerwithlogout .at-navarea .at-searchbox .at-crossbtn {
    display: none;
  }
  .headerwithlogout .at-navarea .at-searcharea {
    left: 0;
    z-index: 2;
    padding: 0;
    width: auto;
    display: block;
    position: relative;
    @include transition;
  }
  .headerwithlogout .at-opensearch .at-searcharea {
    display: none;
  }
  .at-opensearch .at-searcharea {
    background: white;
    display: flex !important;
  }
  .at-navarea .at-searcharea {
    left: 0;
    z-index: 2;
    width: 100%;
    display: none;
    padding: 0 50px;
    position: absolute;
    @include transition;
  }
  .at-searchbox {
    width: 100%;
  }
}
@media (max-width: 880px) {
  .at-videoslist {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }
}
@media (min-width: 970px) and (max-width: 990px) {
  .at-videoslist {
    grid-template-columns: repeat(auto-fill, minmax(284px, 1fr));
  }
}
@media (min-width: 991px) and (max-width: 1000px) {
  .at-videoslist {
    grid-template-columns: repeat(auto-fill, minmax(286px, 1fr));
  }
}
@media (min-width: 1921px) and (max-width: 1970px) {
  .at-videoslist {
    grid-template-columns: repeat(auto-fill, minmax(284px, 1fr));
  }
}
@media (max-width: 991px) {
  // .at-videoslist {
  //   grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  // }
  .at-recommendedinfo .at-infobtn {
    min-width: 100%;
    padding: 0 20px;
  }
  .at-bannervideobox {
    width: 100%;
    padding: 0;
    // margin: 0 0 20px 0;
  }
  .at-addarea {
    width: 100%;
    display: none;
    text-align: center;
  }
  .at-banner-area .at-addbox {
    width: 300px;
    margin: 0 auto;
  }
  .at-searchbox {
    width: 100%;
  }
  .at-content-area {
    padding: 15px 30px;
  }
  .at-videodetails .at-videocontent .at-viewsbox {
    margin-bottom: 10px;
  }
  .at-likeandrating-area {
    width: 100%;
  }
  .at-videodetails .at-videocontent .at-likeandrating-area {
    width: auto;
  }
  .at-likeunlikestatus {
    display: flex;
  }
  .at-vd-infoandcomment .at-userboxarea .at-followbtn {
    margin-right: 10px;
  }
  .at-videodetails .at-videolist .at-videocontent .at-videoinfobox {
    flex-direction: row;
  }
  .at-channel-banner .at-banner-area {
    flex-direction: column;
  }
  .at-channel-banner .at-userbox {
    margin: 0 auto 20px;
  }
  .at-channel-banner .at-bannervideobox {
    width: 100%;
    margin-bottom: 20px;
  }
  .at-uploadform fieldset {
    flex-direction: column;
  }
  .at-uploadform .at-leftbox {
    margin-right: 40px;
    margin: 0 auto 40px;
  }
  .at-uploadform .at-rightbox {
    width: 100%;
  }
  .at-settings .at-contentbox .at-leftbox {
    padding-right: 0;
  }
  .at-settings .at-contentbox {
    flex-direction: column;
  }
  
  .at-settings .at-contentbox .at-rightbox {
    width: 100%;
  }
  .at-tabcontent .at-maincontent {
    padding: 30px 20px 15px;
  }
  .at-tabcontent .at-channelinfo .at-maincontent {
    padding: 30px 20px 30px;
  }
  .at-subscription .at-headcontent {
    padding: 30px 20px;
  }
  .at-tabcontent .at-footercontent {
    padding: 25px 20px 20px;
  }
  .at-subscription .at-palnbox label.at-customcheckbox {
    margin: 5px 20px 0 0;
  }
  .at-subscription .at-palnbox h4 {
    width: calc(100% - 120px);
  }
  .at-earningarea {
    padding: 30px 40px;
  }
  .at-earningbox {
    padding: 0;
  }
  .at-earningstats .at-themeform .form-control {
    width: 100%;
    margin: 0 0 15px;
  }
  .at-statisticbox {
    width: 50%;
  }
  .at-buystardust .at-stardustlist {
    width: 100%;
  }
  .at-buystardust .at-stardustlist li .at-right {
    padding: 0 110px 0 0;
  }
  .at-buystardust .at-stardustlist li .at-right span {
    right: -10px;
  }
  
  
  .at-yourvideos .at-content .at-videobox {
    flex-direction: column;
  }
}
@media (max-width: 767px) {
  .at-logoarea {
    margin: 0 0 50px;
  }
  .at-logoarea .at-logo {
    width: 160px;
  }

  .at-loginarea .at-content {
    width: 600px;
  }
  .at-navarea .at-searchbtn {
    display: block;
    margin-left: auto;
  }
  .at-navarea .at-searchbox .at-crossbtn {
    display: block;
  }
  .at-navarea .at-searcharea {
    left: 0;
    z-index: 2;
    width: 100%;
    display: none;
    padding: 0 50px;
    position: absolute;
    @include transition;
  }
  .at-opensearch .at-searcharea {
    display: block;
  }
  .at-recommendedinfo {
    flex-direction: column;
    margin: 0;
  }
  .at-bannervideobox {
    flex-direction: column;
    padding-top: 0;
    padding-bottom: 0;
    margin: 0;
  }
  .at-videoboxarea {
    width: 100%;
    display: flex;
    margin: 0 0 10px;
  }
  .at-videoboxarea .at-videobox {
    margin: 0;
    width: 50%;
    padding: 0 5px;
  }
  .at-slidercontainer {
    padding: 0;
    width: 100%;
  }
  .at-slidercontainer .at-viewvideobox {
    width: 100%;
    margin-bottom: 10px;
  }
  .at-addarea {
    width: 100%;
  }
  .at-banner-area .at-addbox {
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }
  .at-yourvideos .at-content .at-videobox .at-actions a {
    padding: 0 10px;
  }
  .at-yourvideos .at-content .at-videobox .at-videocontent h4 {
    font-size: 2.4rem;
  }
  .at-vd-infoandcomment .at-userboxarea .at-btnarea {
    display: block;
    padding: 15px 0 0;
  }
  .at-sidebar .at-addbox {
    width: 100%;
    margin: 0 0 20px;
  }
  .at-sidebar .at-stardustlist-box {
    width: 100%;
  }
  .at-subscription .at-cardinfobox {
    width: 100%;
  }
  .at-analytics .at-pageheading {
    flex-direction: column;
    align-items: flex-start;
  }
  .at-analytics .at-pageheading h2 {
    margin: 0 0 20px;
  }
  .at-datebox.ml-auto {
    margin: 0 !important;
  }
  .picker__container {
    right: 47px !important;
  }
  .at-video {
    // height: 120px;
  }
  .at-termsofservice .at-contentbox {
    padding: 30px;
  }
  .at-videodetails .at-videocontent .at-videoinfobox {
    flex-direction: column;
  }
  .at-videodetails .at-videocontent .at-likeandrating-area {
    width: 100%;
    margin: 0 0 10px;
  }
  .at-videodetails .at-videocontent .at-likeandrating-area .at-videorating {
    margin-left: auto;
  }
  .at-vd-infoandcomment .at-userboxarea .at-userbox .at-themeimage {
    width: 50px;
    height: 50px;
    line-height: 50px;
  }

  .headerwithlogout .at-navarea .at-searchbtn {
    display: block;
    margin-left: auto;
  }
  .headerwithlogout .at-navarea .at-searchbox .at-crossbtn {
    display: block;
  }
  .headerwithlogout .at-navarea .at-searcharea {
    left: 0;
    z-index: 2;
    width: 100%;
    display: none;
    padding: 0 50px;
    position: absolute;
    @include transition;
  }
  .headerwithlogout.at-opensearch .at-searcharea {
    display: block;
  }
  .headerwithlogout .at-searchbox {
    width: 100%;
  }
}
@media (max-width: 639px) {
  .at-loginarea .at-content {
    width: 500px;
    padding: 35px 50px;
  }
  .at-footarea {
    padding: 30px 0;
  }
  .at-btn {
    padding: 10px 35px;
  }
  .at-rightarea .at-beacreaterbtn,
  .at-rightarea .at-loginbtn {
    padding: 10px 15px;
  }
  .at-commentsarea .at-commentsendbox {
    flex-direction: column;
    align-items: start;
  }
  .at-commentsarea .at-commentsendbox .at-themefrom {
    width: 100%;
    margin: 0 0 15px;
  }
  .at-commentsarea .at-commentsendbox .at-commenttextaddstardust {
    flex-direction: column;
  }
  .at-commentsarea .at-commentsendbox .at-commenttext {
    width: 100%;
    margin: 0 0 20px;
  }
  .react-multiple-carousel__arrow {
    min-width: 30px;
    min-height: 30px;
  }
  .react-multiple-carousel__arrow::before {
    font-size: 15px;
  }
  .at-uploadform .at-leftbox {
    width: 100%;
  }
  .at-buystardust .at-contentbox {
    padding: 30px;
  }
  .at-stardustlist {
    width: 100%;
  }
  .at-earnings .at-contentbox {
    padding: 20px;
  }
  .at-statisticbox {
    width: 100%;
  }
  .picker__container {
    right: 0 !important;
  }
  .at-buystardust .at-stardustlist {
    width: 100%;
  }
  .at-buystardust .at-stardustlist li .at-right {
    margin: 0;
    padding: 0;
    flex-direction: column;
  }
  .at-buystardust .at-stardustlist li .at-pricebtn {
    margin: 5px auto;
  }
  .at-buystardust .at-stardustlist li .at-right del {
    position: static;
  }
  .at-buystardust .at-stardustlist li .at-right span {
    position: static;
  }
  .at-dropdown button,
  .at-dropdown button.btn,
  .at-dropdown .btn {
    font-size: 18px;
  }
  .at-headerarea {
    align-items: center;
    padding: 30px 20px 0;
  }
  .at-headerarea .at-logo {
    margin: 0 0 10px;
  }
  .at-navarea .at-searcharea {
    padding: 0 15px;
  }
  .at-navarea {
    width: 100%;
    .at-searchbtn {
      margin: 0;
    }
    .at-rightarea {
      width: 100%;
    }
  }

  .headerwithlogout .at-navarea {
    width: auto;
    .at-searchbtn {
      margin-left: auto;
    }
    .at-rightarea {
      width: auto;
    }
  }
  .at-chartbox .at-head {
    flex-direction: column;
    align-items: flex-start;
  }
  .at-sortarea {
    margin: 10px 0 0;
  }
  .headerwithlogout.at-headerarea {
    flex-direction: row;
    padding: 15px 20px 0;
    .at-logo {
      margin: 0;
    }
  }
  .at-container-pandding {
    padding: 0 20px;
  }
  .headerwithlogout .at-navarea .at-searcharea {
    padding: 0 20px;
  }
}
@media (max-width: 600px) {
}
@media (max-width: 576px) {
  html {
    font-size: 44% !important;
  }
  .at-loginholder {
    width: 100%;
  }
  .at-loginarea .at-content {
    width: 100%;
    padding: 25px 15px;
  }
  .at-headerarea {
    flex-direction: column;
    align-items: center;
    padding: 30px 20px 0;
  }
  .at-headerarea .at-logo {
    margin: 0 0 10px;
  }
  
  .at-yourvideos {
    padding: 20px;
  }
  .at-pageheading {
    padding: 20px 0;
    flex-direction: column;
  }
  .at-paginationarae {
    margin: 20px 0 0;
  }
  .at-container-pandding {
    padding: 0 20px;
  }
  .at-contentbox {
    padding: 20px 15px;
  }
  .at-videodetails .at-content {
    padding: 15px;
  }
  .picker__container {
    right: 24px !important;
  }
  .calendar__list {
    display: block;
    width: 100%;
  }
  .calendar__item {
    display: block;
  }
  .at-navarea {
    width: 100%;
    .at-searchbtn {
      margin: 0;
    }
    .at-rightarea {
      width: 100%;
    }
    .at-notificationdropdown {
      margin-left: auto;
    }
  }
  .at-channel-banner .carousel-control-prev {
    left: 0;
  }
  .at-channel-banner .carousel-control-next {
    right: 0;
  }
  .at-rightarea .at-loginbtn {
    margin-left: auto;
  }
  .at-rightarea .at-loginbtn i {
    margin: 0;
    display: block;
  }
  .at-buystardust .at-stardustlist li img {
    margin: 0;
  }
  .at-rightarea .at-loginbtn span {
    display: none;
  }
  .at-buystardust .at-stardustlist li .at-right {
    margin: 0;
    flex-direction: column;
  }
  .at-buystardust .at-stardustlist li .at-pricebtn {
    margin: 5px auto;
  }
  .at-videoplay video {
    /* height: 250px;
        max-height: 375px; */
  }
  .at-dropdown .at-dropdowncontent {
    width: 360px;
  }
  .at-notificationdropdown .at-dropdowncontent {
    -webkit-transform: translate3d(-330px, 35px, 0) !important;
    transform: translate3d(-330px, 35px, 0) !important;
  }
  .at-loginholder {
    padding: 50px 15px;
  }
  .at-headerarea {
    padding: 15px 15px 0;
  }
  .rt-playerdata {
    padding: 5px 0 !important;
  }
  .at-videodetails .at-videocontent .at-likeandrating-area .at-like,
  .at-videodetails .at-videocontent .at-likeandrating-area .at-dislike {
    font-size: 16px;
  }
  .at-videodetails .at-videocontent .at-likeandrating-area .at-like i,
  .at-videodetails .at-videocontent .at-likeandrating-area .at-dislike i {
    font-size: 20px;
  }
  .at-videodetails .at-videocontent .at-likeandrating-area .at-videorating {
    font-size: 15px;
    img {
      width: 20px;
    }
  }
  .at-videodetails .at-videocontent .at-likeandrating-area {
    margin: 0;
  }
  .at-vd-infoandcomment .at-userboxarea .at-btnarea {
    padding: 0;
  }
  .at-slidercontainer .at-viewvideobox,
  .at-slidercontainer .slick-center .at-viewvideobox {
    height: auto;
    margin: 0;
  }
  .at-commentbox .at-commentcontent .at-title .at-stardust img {
    width: 16px;
  }
  .at-footarea p {
    font-size: 12px;
  }
}
@media (max-width: 479px) {
  .at-container-pandding {
    padding: 0 15px;
  }
  .at-banner .at-banner-area {
    padding: 15px;
  }
  .at-videoslist {
    padding: 15px;
  }
  .at-content-area {
    padding: 15px 10px;
  }
  .at-yourvideos .at-content .at-videobox .at-actions a {
    padding: 0 5px;
  }
  .at-yourvideos .at-content .at-videobox .at-videocontent h4 {
    margin-bottom: 10px;
  }
  .at-yourvideos .at-content .at-videobox .at-videocontent .at-description {
    margin-bottom: 15px;
  }
  .at-yourvideos .at-content .at-videobox .at-actions {
    margin-bottom: 15px;
  }
  /* .at-videodetails .at-content{
        padding: 20px;
    } */
  .at-commentbox {
    // flex-direction: column;
  }
  .at-commentsarea .at-themeimage {
    width: 40px;
    height: 40px;
    line-height: 40px;
    margin-right: 10px;
  }
  .at-commentbox .at-commentcontent {
    width: calc(100% - 50px);
  }
  .at-commentsarea .at-head {
    align-items: start;
    flex-direction: column;
  }
  .at-commentsarea .at-head h3 {
    margin-bottom: 10px;
  }
  .at-sidebar {
    padding: 15px;
  }
  .at-loginholder .form-group.d-flex {
    // flex-direction: column;
  }
  .at-themeform label.at-customcheckbox {
    margin: 10px 0 0;
    align-self: start;
    padding: 4px 0 4px 30px;
  }
  .at-signup .at-themeform label.at-customcheckbox {
    margin: 0;
  }
  .at-footarea {
    padding: 15px 0;
  }
  .at-profilepage .at-rightbox .at-btn {
    margin-top: 20px;
  }
  .at-sidebar .at-stardustlist-box .at-stardustlist li {
    flex-direction: row;
  }
  .at-stardustlist li i {
    margin: 0 0 10px;
  }
  .at-stardustlist li span {
    margin-bottom: 10px;
  }
  .at-stardustlist li .at-pricebtn {
    margin: 0;
  }
  .at-navtabs .nav-item .nav-link {
    padding: 20px;
  }
  .at-notificationlist li {
    flex-direction: column;
  }
  .at-notificationlist li .at-switch {
    margin: 0 0 10px;
  }
  .at-tabcontent .at-content h4 {
    width: 100%;
  }
  .at-subscription .at-palnbox {
    flex-direction: column;
  }
  .at-subscription .at-headcontent {
    padding: 20px;
  }
  .at-subscription .at-palnbox label.at-customcheckbox {
    margin: 0 0 10px;
  }
  .at-subscription .at-palnbox .at-price {
    margin: 10px 0 0;
  }
  .range-picker-input {
    width: 100%;
  }
  .at-navarea .at-ratingbox {
    margin-left: 10px;
  }
  .at-video {
    // height: 100px;
  }
  .at-channelvideo .at-videobox {
    width: 100%;
  }
  .at-notificationdropdown .at-dropdowncontent {
    transform: translate3d(-260px, 35px, 0) !important;
  }
  .at-yourvideos .at-content .at-videobox .at-video {
    width: 100%;
  }
  .at-navarea .at-notificationdropdown {
    margin-right: 8px;
  }
  .at-commentbox .at-commentcontent .at-title h3 {
    font-size: 14px;
  }
  .at-commentbox .at-commentcontent .at-description p {
    font-size: 12px;
  }
  .at-commentbox .at-commentcontent .at-title .at-stardust {
    font-size: 14px;
    i.icon-ratingstars {
      font-size: 14px;
    }
    i {
      font-size: 12px;
    }
  }
  .at-loginarea .at-themeform .at-btn {
    padding: 10px 30px;
    height: 40px;
  }
}
@media (max-width: 359px) {
  .at-customcheckbox {
    padding-left: 24px;
  }
  .at-yourvideos .at-content .at-videobox .at-actions {
    flex-direction: column;
  }
  .at-yourvideos .at-content .at-videobox .at-actions a {
    padding: 5px 0;
  }
  .at-vd-infoandcomment .at-userboxarea .at-userbox {
    display: flex;
    margin: 0 0 10px;
    align-items: center;
    flex-direction: column;
  }
  .at-vd-infoandcomment .at-userboxarea .at-usercontent {
    width: 100%;
    text-align: center;
  }
  .at-vd-infoandcomment .at-userboxarea .at-btnarea {
    text-align: center;
  }
  .at-vd-infoandcomment .at-userboxarea .at-followbtn {
    margin: 0 0 15px;
  }
  .at-channel-banner .at-banner-area .at-addbox {
    width: 100%;
  }
  .at-channel-banner .at-userbox {
    width: 100%;
  }
  .at-navarea .at-ratingbox {
    margin-left: 5%;
  }
  .at-dropdown .at-dropdowncontent {
    width: 320px;
  }
  .at-profiledropdown .at-dropdowncontent {
    transform: translate3d(-256px, 48px, 0) !important;
  }
  .at-notificationdropdown .at-dropdowncontent {
    transform: translate3d(-210px, 35px, 0) !important;
  }
  .at-navarea .at-ratingbox {
    width: 86px;
    margin-left: 10px;
  }
  .at-ratingbox span {
    margin-right: inherit;
  }
  .at-navarea .at-notificationdropdown {
    margin: 0 8px;
  }
  .at-vd-infoandcomment .at-userboxarea .at-followbtn {
    margin: 0 10px 15px 0;
  }
  .at-commentbox .at-commentcontent .at-title {
    flex-wrap: wrap;
  }
  .at-commentbox .at-commentcontent .at-title .at-stardust {
    margin: 3px 0 0;
  }
}
@media (max-width: 320px) {
}

@media (max-width: 1200px) {
  .at-userbox {
    display: none;
  }
  .channel-page {
    width: 100% !important;
  }
}
@mixin placeholders($color) {
    &::-webkit-input-placeholder {
        color: $color !important;
        font-family: $manrope !important;
    }

    &:-moz-placeholder {
        /* Firefox 18- */
        color: $color !important;
        font-family: $manrope !important;

    }

    &::-moz-placeholder {
        /* Firefox 19+ */
        color: $color !important;
        font-family: $manrope !important;
        
    }

    &:-ms-input-placeholder {
        color: $color !important;
        font-family: $manrope !important;
    }
}

@mixin box-shadow() {
    -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.10); /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.10); /* Firefox 3.5 - 3.6 */
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.10); /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
}

@mixin bd-radius($value) {
    -webkit-border-radius: $value;
    -moz-border-radius: $value;
    border-radius: $value;
}

@mixin transition() {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

@mixin after-before() {
    top: 0;
    left: 0;
    content: '';
    position: absolute;
}
@mixin ellipsis() {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
@mixin cardlayout {
    background: $cardLayoutColor1;
    @include bd-radius(10px);
    @include box-shadow();
}
@mixin cardlayout2 {
    background: $WhiteSmoke;
    @include bd-radius(10px);
}
@mixin scrollbar {
    &::-webkit-scrollbar-track {
        border-radius: 10px; 
        background-color: $white;
    }
        
    &::-webkit-scrollbar {
        width: 6px;
        background-color: $white; 
    }
        
    &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background:$DarkGray;
    }
}


@import "scss/mixin";
@import "scss/veriable";
/* =============================================
			Reset css
============================================= */
:root {
  --primary-font: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
}

* {
  margin: 0px;
  padding: 0px;
}
*:after,
*:before {
  @include transition();
}

html {
  font-size: 62.5% !important;
  // overflow-x: hidden;
  overflow-y: scroll;
}
body {
  height: 100%;
  color: $newDimGray;
  font-size: 1.6rem;
  background: $white;
  font-family: $manrope;
  font-weight: 400 !important;
  line-height: 1.3 !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $newDimGray;
  line-height: 1.3;
  font-weight: 600;
  font-family: $manrope;
  // text-transform: capitalize;
}
h1 {
  font-size: 4rem;
}
h2 {
  font-size: 3rem;
}
h3 {
  font-size: 2.2rem;
}
h4 {
  font-size: 1.6rem;
}
h5 {
  font-size: 1.4rem;
}
h6 {
  font-size: 1.2rem;
}
a {
  text-decoration: none;
  @include transition;
}
a:hover {
  text-decoration: none;
}
figure {
  margin-bottom: 6px;
  position: relative;
}
img {
  height: auto;
  max-width: 100%;
}
abbr,
address,
article,
aside,
audio,
b,
blockquote,
body,
canvas,
caption,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
fieldset,
figcaption,
figure,
footer,
form,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
p,
pre,
q,
samp,
section,
small,
span,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: 0 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:after,
blockquote:before,
q:after,
q:before {
  content: "";
  content: none;
}
button {
  border: 0;
  background: none;
  outline: 0 !important;
  &:focus {
    outline: 0 !important;
  }
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

input,
select {
  vertical-align: middle;
}
textarea,
select,
.At-Select select,
.form-control,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.At-UneditableInput {
  border: 1px solid #191D32;
  height: 5rem;
  outline: none;
  color: $white;
  font-size: 1.6rem;
  font-weight: 500;
  box-shadow: none;
  line-height: 1.3;
  padding: 15px 30px;
  font-family: $manrope;
  background: $newMainBlack;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  @include bd-radius(6px);
  -webkit-box-shadow: none;
  &:focus {
    box-shadow: none;
    background: $white
  }
  @include placeholders($Gainsboro);
}
input:-internal-autofill-selected {
  background-color: transparent;
}
label {
  font-size: 14px;
  margin: 0 0 5px;
  font-family: $manrope;
}
textarea.form-control {
  resize: none;
  height: 4.5rem;
  padding: 10px 30px;
}

.form-group {
  // margin-bottom: 15px !important;
}
p {
  font-size: 1.6rem;
  font-family: $manrope;
}
.at-btn {
  border: none;
  height: 45px;
  color: $white;
  outline: none;
  font-weight: 600;
  font-size: 1.6rem;
  padding: 0 20px;
  width: max-content;
  @include transition();
  font-family: $manrope;
  background-color: $newMainBlack;
  box-shadow: inset 1px 1px 1px 0px #ff5093, inset 0 0 0 2px #9A37e7;
  border-radius: 10px !important;
  @include bd-radius(6px);
  text-transform: capitalize;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  // &:hover {
  //   color: $white;
  //   background-color: $themecolor;
  // }
}
.at-btn-lg {
  width: 100%;
}
.at-inputwithicon,
.at-inputwithbtn {
  position: relative;
}
.at-inputwithbtn button,
.at-inputwithicon i {
  top: 0;
  right: 0;
  border: 0;
  z-index: 2;
  width: 50px;
  height: 50px;
  background: none;
  line-height: 50px;
  position: absolute;
  text-align: center;
}
.at-inputwithicon button i {
  line-height: inherit;
}
.at-inputwithicon .form-control {
  z-index: 1;
  position: relative;
}
table {
  background-color: transparent;
  max-width: 100%;
}
th {
  text-align: left;
}
table {
  width: 100%;
  margin-bottom: 15px;
}
table > thead > tr > th,
table > tbody > tr > th,
table > tfoot > tr > th,
table > thead > tr > td,
table > tbody > tr > td,
table > tfoot > tr > td {
  border: 0;
  color: $black;
  text-align: left;
  font-weight: 500;
  font-size: 1.3rem;
  padding: 15px 30px;
  vertical-align: top;
  white-space: nowrap;
  font-family: $manrope;
  text-transform: capitalize;
  border-bottom: 1px solid $Gainsboro;
}
table thead tr th {
  padding: 15px 30px 10px;
}
.table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > th,
.table > caption + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > td,
.table > thead:first-child > tr:first-child > td {
  border-top: 0;
}
table > caption + thead > tr:first-child > th,
table > colgroup + thead > tr:first-child > th,
table > thead:first-child > tr:first-child > th,
table > caption + thead > tr:first-child > td,
table > colgroup + thead > tr:first-child > td,
table > thead:first-child > tr:first-child > td {
  border-bottom: 0;
}
table > tbody + tbody {
  border-top: 0;
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  padding: 15px 30px;
}
.table thead tr th {
  padding: 15px 30px 10px;
}
.table tbody tr:last-child td {
  border-bottom: 0;
}
/* =============================================
			theme Image Style
============================================= */
.dropdown-item {
  .at-infobox {
    .at-imagebox {
      .at-themeimage {
        padding: 1px !important;
        -webkit-box-shadow: inset 1px 1px 1px 0px #ff5093, inset 0 0 0 2px #9A37e7 !important;
        box-shadow: inset 1px 1px 1px 0px #ff5093, inset 0 0 0 2px #9A37e7 !important;
        img {
          border-radius: 50px !important;
        }
      }
    }
  }
}

.at-themeimage {
  width: 45px;
  height: 45px;
  overflow: hidden;
  line-height: 45px;
  background: $Java;
  text-align: center;
  @include bd-radius(50%);

  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }

  span {
    color: #fff;
    display: block;
    font-size: 2rem;
    text-transform: uppercase;
  }
}
/* =============================================
			Custom Checkbox Style
============================================= */
.at-customcheckbox {
  margin: 0;
  color: $DimGray;
  cursor: pointer;
  font-weight: 600;
  font-size: 1.4rem;
  margin-left: auto;
  padding-left: 30px;
  position: relative;
  align-self: center;
  font-family: $manrope;
  text-transform: capitalize;
}
.at-customcheckbox input {
  width: 0;
  height: 0;
  opacity: 0;
  cursor: pointer;
  position: absolute;
}
.at-checkmark {
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 3px;
  background-color: $newMainBlack;
  border: 1px solid $newMainBlack;
}
.at-customcheckbox input:checked ~ .at-checkmark {
  background-color: $themecolor;
  border-color: $themecolor;
}
.at-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.at-customcheckbox input:checked ~ .at-checkmark:after {
  display: block;
}
.at-customcheckbox .at-checkmark:after {
  top: 3px;
  left: 7px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
/* =============================================
			Custom Switch style
============================================= */
.at-switch {
  width: 40px;
  height: 12px;
  position: relative;
  display: inline-block;
}
.at-switch input {
  width: 0;
  height: 0;
  opacity: 0;
}
.at-slider {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  position: absolute;
  background-color: $DarkGray;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.at-slider:before {
  left: 0;
  content: "";
  width: 16px;
  height: 16px;
  bottom: -2px;
  position: absolute;
  background-color: $DimGray;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .at-slider {
  background-color: $Gainsboro;
}
input:focus + .at-slider {
  box-shadow: 0 0 1px $Gainsboro;
}
input:checked + .at-slider::before {
  background-color: $themecolor;
}
input:checked + .at-slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.at-slider.round {
  border-radius: 6px;
}
.at-slider.round:before {
  border-radius: 50%;
}
/* =============================================
			Theme style
============================================= */
.modal-open {
  overflow: inherit;
}
.at-wrapper {
  overflow: hidden;
  // min-height: 950px;
  background-color: $white;
  background-position: 100% 100%;
  min-height: 100vh !important;
}

.at-background {
  background-image: url("/assets/images/bg.jpg");
  background-size: cover;
  background-attachment: fixed;
}

.at-container-pandding {
  padding: 30px 50px;
}
/* =============================================
			Login signup page style
============================================= */
.at-signup {
  height: 100% !important;
}
.at-loginarea {
  height: 100vh;
  display: flex;
  overflow: auto;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-repeat: no-repeat;
  background-color: $white;

  .at-content {
    width: 700px;
    text-align: center;
    @include cardlayout;
    padding: 35px 100px;
  }

  .at-themeform {
    .at-btn {
      padding: 10px 50px;
    }

    .at-forgetlink {
      font-weight: 600;
      font-size: 1.4rem;
      margin-left: auto;
      color: $themecolor;
      align-self: center;
      font-family: $manrope;
      text-decoration: underline;
    }
  }
}
.at-terms {
  display: flex;
  margin-left: auto;
  flex-direction: column;
  justify-content: center;

  .at-termsbox {
    display: flex;
    align-items: center;
  }

  a {
    font-size: 1.4rem;
    margin-left: 10px;
    color: $DimGray;

    span {
      color: $themecolor;
      text-decoration: underline;
    }
  }
}
.at-error {
  font-size: 1.4rem;
}
.at-loginholder {
  height: 100%;
  margin: 0 auto;
  padding: 50px 0;
}
.at-logoarea {
  margin: 0 0 65px;

  .at-logo {
    width: 205px;
    display: block;
    margin: 0 auto;

    a {
      display: block;
    }
  }
}
.at-sectionheading {
  h2 {
    color: $black;
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 45px;
    font-family: $manrope;
    text-transform: capitalize;
  }
}

.at-footarea {
  padding: 70px 0 0;

  p {
    color: $DimGray;
    font-size: 14px;
    font-weight: 600;
    font-family: $manrope;
    .at-createaccountlink {
      color: $themecolor;
      text-decoration: underline;
    }
  }
}
.at-themeform label.at-customcheckbox {
  margin: 0;
  font-size: 1.4rem;
  margin-left: auto;
}
.at-signup {
  .at-themeform label.at-customcheckbox {
    display: flex;
    min-height: 20px;
    color: $themecolor;
    padding-left: 20px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
}

/* =============================================
			Sidebar style
============================================= */

.sdbr {
  background-color: #0E1121 !important;
}

.sdbr-mn a {
  font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 400;
  display: inline-block;
}

.stbxcnt {
  .sdbr {
    flex: 0 0 225px;
  }
}

/* =============================================
			Pages style
============================================= */

.legal-content {
  padding: 20px 20px;
  background-color: #0E1121;
  margin: 30px 20px;
  border-radius: 8px;

  h1 {
    font-family: var(--primary-font);
    font-weight: 500;
    font-size: 25px;
    margin-bottom: 15px;
    color: #63a6d8;
  }

  .text {
    font-family: var(--primary-font);
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 15px;
    color: #fff;
    line-height: 20px;
  }
}

/* =============================================
			Header style
============================================= */
.at-header {
  min-width: 0;
}

.at-header-one {
  border-bottom: 1px solid #3A4065;
}

.at-headerarea {
  display: flex;
  z-index: 1000;
  padding: 15px 50px 0 50px;

  .at-logo {
    width: 170px;
    display: block;
    margin-right: 20px;

    a {
      display: block;

      img {
        width: 100%;
        display: block;
      }
    }
  }
}
.at-navarea {
  flex-grow: 1;
  display: flex;
}
.at-searcharea {
  display: flex;
  margin: 0 auto;
}
.at-searchbox {
  width: 500px;
  margin: 0 auto;
  display: flex;
  background: $white;
  @include bd-radius(10px);
  box-shadow: 0 0 15px 0.15px rgba(0, 0, 0, 0.1);

  .at-inputwithbtn {
    width: 100%;

    .form-control {
      width: 100%;
      height: 45px;
      background: $newMainBlack;
      padding: 10px 50px 10px 20px;
    }
    button {
      width: 44px;
      height: 44px;
      color: $Whisper;
      font-size: 2rem;
      line-height: 44px;
    }
  }
  .at-crossbtn {
    border: 0;
    height: 45px;
    display: none;
    outline: none;
    padding: 0 10px;
    font-size: 2rem;
    background: $white;
    color: $DimGraylight;
    @include bd-radius(10px);
  }
}
.at-searchbtn {
  border: 0;
  height: 45px;
  display: none;
  outline: none;
  padding: 0 10px;
  background: none;
  font-size: 2.5rem;
  color: $themecolor;
  @include bd-radius(10px);
}
.at-rightarea {
  display: flex;
  align-items: center;

  .at-beacreaterbtn {
    height: 35px;
    margin: 0 13px;
    i {
      display: none;
      font-size: 1.7rem;
      margin-right: 10px;
    }
  }
  .at-loginbtn {
    height: 35px;
    margin: 0 13px;
    i {
      display: none;
      font-size: 1.7rem;
      margin-right: 10px;
    }
  }
}
.at-ratingbox {
  padding: 5px;
  display: flex;
  margin: 0 13px;
  @include cardlayout;
  align-items: center;
  @include bd-radius(6px);
  img {
    width: 20px;
    margin-right: 10px;
  }
  i.icon-ratingstars {
    font-size: 2.5rem;
    color: $themecolor;
    margin-right: 10px;
  }

  span {
    color: $DimGray;
    font-weight: 700;
    font-size: 1.6rem;
    margin-right: 40px;
    font-family: $manrope;
    display: inline-block;
    vertical-align: middle;
  }

  .at-addbtn {
    width: 26px;
    height: 26px;
    color: $white;
    background: $Grey;
    font-size: 1.6rem;
    @include bd-radius(4px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
    text-shadow: 1px 1px 1px #8080803b;
  }
}
.at-uploadbtn {
  i {
    margin-left: 14px;
  }
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  outline: none;
  color: $DimGray;
  background-color: transparent;
  border-color: transparent;
}
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}
.at-dropdown {
  margin: 0 13px;

  button,
  button.btn,
  .btn {
    border: 0;
    margin: 0;
    padding: 0;
    outline: none;
    font-size: 2rem;
    color: $newDimGray;
    border-radius: 0;
    box-shadow: none;
    overflow: inherit;
    position: relative;
    border: 0 !important;
    background: transparent;

    &:active,
    &:hover,
    &:focus {
      outline: none !important;
      color: $white !important;
      box-shadow: none;
      background: transparent !important;
      background-color: transparent !important;
    }
  }
  a,
  button.btn-primary:not(:disabled):not(.disabled).active,
  button.btn-primary:not(:disabled):not(.disabled):active,
  button.show > .btn-primary.dropdown-toggle,
  .btn-primary:not(:disabled):not(.disabled).active,
  .btn-primary:not(:disabled):not(.disabled):active,
  .show > .btn-primary.dropdown-toggle,
  .btn-secondary:not(:disabled):not(.disabled).active:focus,
  .btn-secondary:not(:disabled):not(.disabled):active:focus,
  .show > .btn-secondary.dropdown-toggle:focus {
    border: 0;
    box-shadow: 0;
    outline: none !important;
    color: $newDimGray !important;
    background: transparent !important;
    background-color: transparent !important;
  }
  .dropdown-toggle::after {
    margin: 0;
    border: 0;
  }

  .at-dropdowncontent {
    margin: 0;
    border: 0;
    width: 480px;
    padding: 5px 0;
    @include cardlayout;

    .at-heading {
      display: flex;
      align-items: center;
      padding: 15px 20px 15px 25px;
      border-bottom: 1.5px solid $DimGraylight;

      h3 {
        color: $white;
        font-size: 2rem;
        margin-bottom: 0;
        font-weight: 700;
        font-family: $manrope;
      }
      .at-settingbtn {
        margin-left: auto;
      }
      i {
        color: $white;
        font-size: 3rem;
        margin-left: auto;
      }
    }
  }

  .dropdown-item {
    padding: 20px 0;

    &:hover,
    &:focus,
    &:active {
      background-color: transparent;
    }
  }
}

.at-notificationdropdown {
  i {
    font-size: 23px;
  }
  
  .at-count {
    top: -6px;
    right: -6px;
    width: 20px;
    height: 20px;
    color: $white;
    font-size: 10px;
    padding: 1px 3px;
    position: absolute;
    text-align: center;
    border-radius: 50%;
    background: #ed4337;
    border: 1px solid $white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  .at-dropdowncontent {
    transform: translate3d(0px, 35px, 0) !important;

    .at-dropdownlist {
      overflow-y: auto;
      max-height: 380px;
      min-height: 200px;
      overflow-x: hidden;
      @include scrollbar;
    }
  }
}

.at-infobox {
  display: flex;

  .at-imagebox {
    position: relative;
    margin-right: 10px;

    .at-themeimage {
      width: 50px;
      height: 50px;
    }
  }

  .at-infocontent {
    h4 {
      white-space: break-spaces;
      color: $Grey;
      font-weight: 600;
      font-size: 1.6rem;
      margin-bottom: 8px;
      font-family: $manrope;
      text-transform: lowercase;

      span {
        color: $black;
        font-weight: 700;
        text-transform: capitalize;
      }
    }

    .at-time {
      color: $Grey;
      display: block;
      font-weight: 600;
      font-size: 1.6rem;
      margin-bottom: 8px;
      font-family: $manrope;
    }

    h5 {
      margin: 0;
      color: $black;
      font-weight: 900;
      font-size: 1.6rem;
      white-space: normal;
      font-family: $manrope;
      text-transform: capitalize;
    }
  }
}

.at-profiledropdown {
  .btn {
    display: flex;
    align-items: center;
  }

  .at-dropdowncontent {
    width: 330px;
    transform: translate3d(-270px, 48px, 0) !important;

    .at-heading {
      padding: 15px 20px 20px 25px;

      h3 {
        margin-bottom: 8px;
        word-break: break-all;
      }

      h4 {
        margin: 0;
        color: $DimGray;
        font-weight: 400;
        font-size: 1.6rem;
        word-break: break-all;
        font-family: $manrope;
        text-transform: lowercase;
      }

      a.at-settingbtn {
        padding-left: 15px;
      }
    }

    .dropdown-item {
      display: flex;
      color: $black;
      padding: 12px 0;
      font-weight: 600;
      font-size: 1.8rem;
      align-items: center;
      font-family: $manrope;
      text-transform: capitalize;

      span {
        width: 200px;
        @include ellipsis();
      }

      i {
        margin-right: 10px;

        &.fa-angle-right {
          margin: 0;
          margin-left: auto;
        }
      }
    }
  }
}
/* =============================================
			banner style
============================================= */
.at-banner {
  margin-top: 10px;

  .at-banner-area {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 35px;
    @include cardlayout;
    align-items: center;
  }
}
.at-recommendedinfo {
  .at-infobtnbox {
    padding: 12px 0;
  }

  .at-infobtn {
    height: 55px;
    display: flex;
    color: $DimGray;
    padding: 0 25px;
    min-width: 290px;
    font-weight: 600;
    font-size: 1.8rem;
    align-items: center;
    @include cardlayout2;
    font-family: $manrope;
    text-transform: capitalize;

    i {
      font-size: 2.5rem;
      color: $themecolor;
      margin-right: 15px;
    }
  }
}

.at-bannervideobox {
  padding: 20px;
  display: flex;
  align-items: center;
  @include cardlayout2;
  margin: 0 29px 0 40px;
  width: calc(100% - 660px);
  background: transparent;
}

.at-videoboxarea {
  width: 222px;
}
.at-videobox {
  width: 100%;
  margin-bottom: 14px;
}

.at-video {
  width: 100%;
  // height: 120px;
  overflow: hidden;
  position: relative;
  aspect-ratio: 16 / 9;
  @include bd-radius(10px);

  img {
    width: 100%;
    // height: 100%;
    display: block;
    aspect-ratio: 16 / 9;
    // object-fit: cover;
  }
  .shimmering {
    width: 100%;
    height: 100%;
  }
  .photo {
    width: 100%;
  }

  .bg-text {
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/opacity/see-through */
    color: white;
    font-weight: bold;
    border: 3px solid #f1f1f1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 80%;
    padding: 20px;
    text-align: center;
  }
}

.at-videoboxarea {
  .at-videobox {
    &:last-child {
      margin: 0;
    }
  }
}

.at-slidercontainer {
  padding: 0 15px;
  // width: calc(100% - 444px);
  width: 100%;

  .at-viewvideobox {
    width: 100%;
    height: 180px;
    margin: 42px 0;
    padding: 0 5px;
    overflow: hidden;
    @include transition;
    @include bd-radius(10px);
    // aspect-ratio: 16 / 9;
    img {
      width: 100%;
      height: 100%;
      margin: 0 auto;
      // aspect-ratio: 16 / 9;
      object-fit: cover;
      border-radius: 10px;
    }
    .shimmering {
      width: 100%;
    }
    .photo {
      width: 100%;
    }
    .lines {
      width: 100%;
    }
  }
  .slick-center {
    .at-viewvideobox {
      margin: 0;
      height: 265px;
    }
  }
  .slick-next:before,
  .slick-prev:before {
    color: $themecolor;
    font-size: 22px;
  }

  .carousel-control-next {
    right: -60px;
  }
  .carousel-control-prev {
    left: -50px;
  }
}
.video-channel-box {
  .at-slidercontainer .at-viewvideobox {
    // width: 530px;
    width: 100%;
  }
}
.carousel-control-prev-icon {
  background: url(/assets/images/angle-left.svg) no-repeat;
}
.carousel-control-next-icon {
  background: url(/assets/images/angle-right.svg) no-repeat;
}
.at-addbox {
  width: 300px;
  overflow: hidden;
  @include bd-radius(10px);

  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
}
.carousel-fade .carousel-item.active {
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  @include box-shadow;
}
/* =============================================
			Video List style
============================================= */
.at-main {
  margin-top: 10px;
  min-width: 0;
}

.at-videoslist {
  width: 100%;
  display: flex;
  padding: 20px 25px;
  @include cardlayout;
  margin-bottom: 30px;
  // z-index: 1;
  clear: both;
  display: grid;
  grid-gap: 20px;
  // position: relative;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

  .at-videobox {
    margin: 0;
  }

  .at-video {
    width: 100%;
    // height: 180px;
    position: relative;
    margin-bottom: 15px;
    aspect-ratio: 16 / 9;
    img {
      aspect-ratio: 16 / 9;
    }
  }
}
.at-tiem {
  right: 3px;
  bottom: 3px;
  color: $white;
  font-size: 10px;
  padding: 3px 5px;
  position: absolute;
  border-radius: 3px;
  background: $DimGraylight2;
}
.at-overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  background: $blacklight;
}

.at-videocontent {
  h4 {
    width: 100%;
    color: $black;
    font-weight: 600;
    font-size: 1.6rem;
    margin-bottom: 10px;
    @include ellipsis();
    font-family: $manrope;
    text-transform: none;

    a {
      color: $black;
    }
  }
}

.at-videoinfobox {
  display: flex;
  align-items: center;

  .at-dalasreview {
    color: $DimGray;
    font-weight: 600;
    font-size: 1.4rem;
    display: flex;
    font-family: $manrope;

    i {
      color: $themecolor;
      margin-left: 5px;
    }
  }

  .at-viewsbox {
    margin-left: auto;
  }
}

.at-viewsbox {
  color: $DimGray;
  font-weight: 600;
  font-size: 1.4rem;
  display: inline-block;
  font-family: $manrope;

  .at-views {
    i {
      display: none;
    }

    em {
      font-style: normal;
    }
  }
}
i.icon-tick {
  padding: 0;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  line-height: 14px;
  text-align: center;
  display: inline-block;
  background: #feb5e1;
  color: #fff !important;
  border: 1px solid #fff;
  font-size: 7px !important;
  top: -1px;
  position: relative;
}

/* =============================================
		    channel page style
============================================= */
.at-channel-banner {
  .at-bannervideobox {
    padding: 0;
    margin: auto;
    background: none;
    .at-slidercontainer {
      width: 100%;
    }
  }
  /* .carousel-control-prev {
        left: -40px;
    }
    .carousel-control-next{
        right: -40px;
    } */
}
.slick-track {
  margin: 0 auto;
}
.at-userbox {
  width: 300px;
  display: flex;
  min-height: 300px;
  align-items: center;
  flex-direction: column;

  .at-contentbox {
    padding: 15px;
    width: inherit;
    min-height: auto;
    text-align: center;
    margin-bottom: 20px;
    background: rgba(255, 255, 255, 0.3);
    @include bd-radius(10px);

    .at-userimage {
      text-align: center;
      margin-bottom: 10px;

      .at-themeimage {
        width: 100px;
        height: 100px;
        line-height: 100px;
        margin: 0 auto;
        span {
          font-size: 3rem;
        }
      }
    }

    h3 {
      color: $white;
      font-weight: 600;
      font-size: 1.8rem;
      margin-bottom: 15px;
      font-family: $manrope;
      text-transform: capitalize;
    }

    .at-description {
      p {
        color: $white;
        line-height: 1.6;
        font-weight: 600;
        font-size: 1.2rem;
        font-family: $manrope;
        word-break: break-word;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
  .at-btnarea {
    display: flex;
  }
  .at-btn {
    margin: 0 auto;
    padding: 10px 30px;
  }
  .at-followbtn {
    color: $white;
    margin-right: 15px;
    background: transparent;
    border: 1px solid $white;
  }
}
.at-videoscategories {
  width: 100%;
  margin-bottom: 20px;

  &:last-child {
    margin: 0;
  }

  .at-heading {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    h2 {
      margin: 0;
      color: $black;
      font-weight: 700;
      font-size: 2.4rem;
      font-family: $manrope;
      text-transform: capitalize;

      i {
        margin: 0 20px;
        font-size: 1.5rem;
      }

      span {
        color: $DimGray;
        font-weight: 600;
        font-size: 2.4rem;
        font-family: $manrope;
        text-transform: capitalize;
      }
    }
  }
  .at-item {
    padding: 10px 10px;
  }
}
.react-multiple-carousel__arrow--right {
  top: 50%;
  margin-top: -43px;
}
.react-multiple-carousel__arrow--left {
  top: 50%;
  margin-top: -43px;
}
.at-channelvideo {
  grid-gap: 0;
  display: flex;
  flex-direction: column;
  grid-template-columns: none;
  padding: 20px 25px;
  @include cardlayout;
  .at-videoslist {
    padding: 0;
    background: transparent;
    box-shadow: none;
  }
  .at-heading {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    h2 {
      margin: 0;
      color: $black;
      font-weight: 700;
      font-size: 2.4rem;
      font-family: $manrope;
      text-transform: capitalize;

      i {
        margin: 0 20px;
        font-size: 1.5rem;
      }

      span {
        color: $DimGray;
        font-weight: 600;
        font-size: 2.4rem;
        font-family: $manrope;
        text-transform: capitalize;
      }
    }
  }
}
.at-videosslider {
  display: flex;
  flex-wrap: wrap;

  .carousel-item {
    .at-videobox {
      width: 20%;
      float: left;
    }
  }
}
.at-subscribemodal {
  .at-modalcontentarea {
    flex-direction: column;
  }
  .at-themefrome {
    width: 100%;
  }
  .at-text {
    display: block;
    font-size: 1.8rem;
    text-align: center;
    margin-bottom: 20px;
  }
  .from-group {
    display: flex;
  }
  .at-btn {
    width: 45%;
    margin: 0 10px;
  }
  .at-footarea {
    padding-top: 20px;
    text-align: center;
  }
}
.at-addsubcribemodal {
  .at-themefrome {
    width: 100%;
  }
  .at-themeimage {
    width: 140px;
    height: 140px;
    line-height: 140px;
    margin: 0 auto 40px;
    span {
      font-size: 4rem;
    }
  }
  .from-group {
    margin: 0 0 20px;
    p {
      text-align: center;
    }
  }
  .form-control {
    @include placeholders($Grey);
  }
  .at-btn {
    margin: 0 auto;
  }
}

/* =============================================
		    Your Video page style
============================================= */
.at-content-area {
  @include cardlayout;
  padding: 20px 20rem;
  margin-bottom: 30px;
}

.at-pageheading {
  display: flex;
  padding: 30px 0;
  align-items: center;

  h2 {
    margin: 0;
    color: #FFF;
    font-size: 2.4rem;
    font-weight: 500;
    text-transform: initial;
    font-family: var(--primary-font);
  }
}

.at-contentbox {
  @include cardlayout2;
  background: $Snow;
  min-height: 600px;
  padding: 30px 40px;
}
.at-yourvideos {
  .noVideosMessage {
    text-align: center;
    margin-top: 10%;
  }

  .at-searchbox {
    width: 100%;
    box-shadow: none;
    margin-bottom: 30px;
    background: $Whisper;

    .at-inputwithbtn .form-control {
      height: 50px;
      padding: 10px 20px;
      background: $Whisper;
      @include placeholders($DimGraylight2);
    }
  }

  .at-content {
    .at-videobox {
      display: flex;
      margin-bottom: 30px;

      .at-video {
        position: relative;
        width: 310px;
        // height: 180px;
        margin: 0 30px 0 0;
        aspect-ratio: 16 / 9;
      }

      .at-videocontent {
        width: calc(100% - 330px);

        h4 {
          width: 100%;
          font-weight: 700;
          font-size: 2.8rem;
          margin-bottom: 15px;
          text-transform: initial;
        }

        .at-description {
          width: 100%;
          height: 50%;
          overflow: hidden;
          margin-bottom: 20px;
          text-overflow: ellipsis;
          white-space: nowrap;

          p {
            margin: 0;
            width: 100%;
            color: $DimGray;
            font-weight: 400;
            font-size: 1.8rem;
            @include ellipsis;
            font-family: $manrope;
          }
        }
      }
      .at-actions {
        display: flex;
        margin-bottom: 35px;

        a {
          padding: 0 20px;
          font-weight: 700;
          font-size: 1.5rem;
          color: $themecolor;
          font-family: $manrope;
          text-transform: uppercase;
          &:hover {
            color: $themecolor;
          }
          &:first-child {
            padding-left: 0;
          }

          i {
            font-size: 2rem;
            margin-right: 5px;
          }
        }
      }
      .at-videoinfobox {
        .at-views {
          font-size: 2rem;
          color: $DimGray;
          font-weight: 700;
          margin-right: 30px;
          font-family: $manrope;
          i {
            color: $black;
            font-size: 2rem;
            margin-right: 10px;
          }

          em {
            display: none;
          }
        }
        .at-like,
        .at-dislike {
          font-size: 2rem;
          color: $DimGray;
          font-weight: 700;
          margin-right: 30px;
          font-family: $manrope;
          cursor: pointer;
          i {
            color: $black;
            font-size: 2rem;
            margin-right: 10px;
          }
        }
      }
    }
  }
}

.at-paginationarae {
  display: flex;
  margin-left: auto;
  align-items: center;

  .at-title {
    color: $DimGray;
    font-weight: 700;
    font-size: 1.6rem;
    margin-right: 10px;
    font-family: $manrope;
    display: inline-block;
    vertical-align: middle;
    text-transform: capitalize;
  }

  .pagination {
    li {
      padding: 0 5px;

      a {
        cursor: pointer;
        font-weight: 600;
        font-size: 1.5rem;
        padding: 3px 10px;
        color: $themecolor;
        border-radius: 4px;
        border: 1px solid $themecolor;
        &:hover {
          color: $white;
          background: $themecolor;
        }
      }
    }
  }
}

/* =============================================
		    Be A Creater page style
============================================= */
.at-be-acreater {
  padding: 30px 20rem 60px;

  .at-themeform {
    margin: 0 auto;
    padding: 30px 0;
    max-width: 800px;
  }

  .at-addmorebtn {
    border: 0;
    margin: 0;
    float: right;
    outline: none;
    font-weight: 600;
    background: none;
    font-size: 1.4rem;
    color: $themecolor;
    font-family: $manrope;
    text-transform: uppercase;
    text-decoration: underline;
  }
  .at-addmore {
    .form-control {
      margin-bottom: 20px;
    }
  }
}

.at-themeform {
  .form-group {
    // margin-bottom: 20px !important;
  }

  label {
    color: $newDimGray;
    margin: 0 0 5px;
    font-weight: 600;
    font-size: 1.8rem;
    font-family: $manrope;
    text-transform: capitalize;
  }

  .form-control {
    padding: 5px 15px;
    background: #191D32;
    font-weight: 700;
    font-size: 16px;
    @include placeholders($DimGraylight2);
    &:focus {
      background: #191D32;
    }
  }
}

/* =============================================
		    Upload Your Video page style
============================================= */
.at-uploadform {
  fieldset {
    display: flex;
  }
  .form-group {
    overflow: unset;
  }
  .at-leftbox {
    width: 375px;
    margin-right: 100px;

    .at-btn {
      margin: 0 auto 20px;
    }

    h6 {
      margin: 0;
      color: $DimGray;
      font-size: 14px;
      text-align: center;
      font-weight: 600px;
      font-family: $manrope;
      text-transform: capitalize;
    }
  }
  .at-uploadbox-area {
    @include cardlayout;
    margin-bottom: 35px;
    padding: 20px 20px 30px;
  }

  .at-uploadbox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span {
      color: $white;
      display: block;
      font-size: 12px;
      font-weight: 600;
      line-height: inherit;
      font-family: $manrope;
    }

    .at-themeimage span {
      font-size: 3rem;
    }
  }

  .at-uploadfile {
    // width: 100%;
    // height: 130px;
    // font-size: 24px;
    display: flex;
    overflow: hidden;
    // color: $DarkGray;
    margin: 0 0 20px;
    // line-height: 160px;
    text-align: center;
    // aspect-ratio: 16 / 9;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background: #020410;
    @include bd-radius(10px);

    i {
      line-height: inherit;
    }

    img {
      // width: 100%;
      // height: 100%;
      display: block;
      aspect-ratio: 16 / 9;
      // object-fit: cover;
    }

    input[type="file"] {
      opacity: 0;
      position: absolute;
      visibility: hidden;
    }
  }

  .at-rightbox {
    width: calc(100% - 375px);

    .at-categorilist {
      list-style: none;
      margin: 10px 0 20px;
      display: grid;
      grid-template-columns: repeat(auto-fill, 190px);
      justify-content: flex-start;
      align-items: stretch;

      li {
        width: 100%;
        padding: 8px 0;
        justify-self: stretch;
      }
    }
  }
  .at-publicprivate {
    padding: 15px 0;
    .at-customcheckbox {
      margin-right: 30px;
    }
    .at-text {
      display: block;
      color: $DimGray;
      font-size: 14px;
      margin-top: 15px;
      font-weight: 500;
      font-family: $manrope;
    }
  }
}
.at-tags {
  .at-text {
    display: block;
    color: $DimGray;
    font-size: 14px;
    margin-top: 15px;
    font-weight: 500;
    font-family: $manrope;
  }
}

.at-thememodal {
  .modal-dialog {
    max-width: 620px;
  }
  .modal-content {
    @include bd-radius(8px);
  }
  .modal-header {
    padding: 20px;
    align-items: center;
    border: 1px solid $Whisper;

    .close {
      padding: 0;
      font-size: 3rem;
      margin: 0 0 0 auto;
    }
  }
  .modal-title {
    margin: 0;
    font-weight: 700;
    font-size: 2.2rem;
    font-family: $manrope;
    text-transform: capitalize;
  }
  .modal-body {
    padding: 30px;
  }
  .at-modalcontentarea {
    display: flex;
    .at-content {
      h4 {
        color: $black;
        margin: 0 0 15px;
        font-weight: 600;
        font-size: 1.8rem;
        font-family: $manrope;
      }
      .at-description {
        margin-bottom: 25px;
        p {
          margin: 0;
          font-weight: 400;
          font-size: 1.3rem;
          line-height: 1.7;
          font-family: $manrope;
        }
      }
      .at-cancelbtn {
        color: $white;
        font-weight: 600;
        font-size: 1.5rem;
        background: $black;
        font-family: $manrope;
        @include bd-radius(22px);
      }
    }
  }
  .at-progressbox {
    margin-right: 40px;
    .progress {
      margin: 0;
      width: 165px;
      background: $Solitude;
      @include bd-radius(50%);
      height: 165px !important;
      span {
        width: 160px;
        height: 160px;
        line-height: 160px;
        font-size: 2.5rem;
        text-align: center;
        color: $DimGray;
      }
    }
    .progress-bar {
      height: 100% !important;
      border-radius: 5;
      background: transparent;
      border: 1px solid $themecolor;
    }
  }
}
.css-yk16xz-control,
.css-1pahdxg-control,
.css-1pahdxg-control:focus,
.css-1pahdxg-control:active,
.css-1pahdxg-control:hover,
.css-yk16xz-control:focus {
  color: $black;
  box-shadow: none;
  font-size: 1.6rem;
  border: 0 !important;
  border-radius: 6px !important;
  background: #191D32 !important;
}
.css-1fhf3k1-control {
  background: #191D32 !important;
}
.css-g1d714-ValueContainer {
  padding: 2px 30px !important;
}
.css-1wa3eu0-placeholder {
  margin: 0;
  font-size: 1.6rem;
}
.css-b8ldur-Input {
  margin: 0 2px !important;
  padding: 0 !important;
}
.css-1rhbuit-multiValue {
  background: $white !important;
  border-radius: 4px !important;
}
.css-1okebmr-indicatorSeparator,
.css-109onse-indicatorSeparator {
  display: none;
}
.select__input {
  input {
    width: 100% !important;
  }
}
.select__menu {
  color: $black;
  font-size: 1.6rem;
  margin: 0 !important;
}

/* =============================================
		    Profile page style
============================================= */
.at-profilepage {
  .at-leftbox {
    text-align: center;
  }

  .at-themeimage {
    width: 150px;
    height: 150px;
    line-height: 150px;
  }

  .at-uploadform {
    .at-uploadfile.at-btn {
      width: auto;
      height: auto;
      height: 45px;
      color: $white;
      cursor: pointer;
      padding: 0 45px;
      line-height: 45px;
      font-size: 1.6rem;
      position: relative;
      display: inline-block;
      background: $themecolor;
      @include bd-radius(6px);
      aspect-ratio: auto;

      input[type="file"] {
        left: 0;
        width: 0;
        right: 0;
        height: 0;
        padding: 0;
        position: absolute;
        line-height: normal;
      }
    }
  }

  .at-rightbox {
    .at-btn {
      margin-top: 55px;
    }
  }
}

/* =============================================
            Settings page style
============================================= */
.at-settings .at-contentbox {
  display: flex;

  .at-leftbox {
    padding-right: 70px;
  }
  .at-rightbox {
    width: calc(100% - 440px);
  }
}
.at-navtabs {
  border: 0;
  width: 370px;
  @include cardlayout;
  flex-direction: column;

  .nav-item {
    margin: 0;
    .nav-link {
      border: 0;
      display: flex;
      color: $newDimGray;
      font-weight: 600;
      font-size: 1.6rem;
      align-items: center;
      font-family: $manrope;
      text-transform: capitalize;
      padding: 15px 25px;
      transition: auto;

      &:focus,
      &:hover,
      &.active {
        padding: 15px 25px;
        background-color: #191D32;
        border: 1px solid #3A4065;
        border-radius: 5px;
      }

      i {
        width: 14px;
        height: 14px;
        margin-left: auto;
        font-size: 1.7rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
.at-tabcontent {
  .at-content {
    @include cardlayout;
    margin: 0 0 65px;
  }

  .at-maincontent {
    padding: 30px 40px 15px;

    .at-changepasswordbtn {
      border: 0;
      float: right;
      outline: none;
      margin-top: 10px;
      font-weight: 600;
      margin-left: auto;
      font-size: 1.5rem;
      color: $themecolor;
      font-family: $manrope;
      background: transparent;
      text-transform: capitalize;
      text-decoration: underline;
    }
  }
  .at-footercontent {
    display: flex;
    padding: 35px 40px 40px;
    border-top: 1px solid #3A4065;
  }
  .stardeosacbtn {
    color: $DimGray;
    font-weight: 600;
    font-size: 1.6rem;
    font-family: $manrope;
    text-transform: capitalize;
  }
  .deleteacbtn {
    border: 0;
    outline: none;
    font-weight: 600;
    margin-left: auto;
    font-size: 1.5rem;
    color: $themecolor;
    font-family: $manrope;
    background: transparent;
    text-transform: uppercase;
  }
  .at-channelinfo {
    .at-maincontent {
      padding: 30px 40px 30px;
    }
    .form-group {
      span {
        display: block;
        color: $DimGray;
        // margin: 10px 0 0;
        font-size: 14px;
        font-weight: 400;
        font-family: $manrope;
      }
    }
  }
  .at-content {
    h3 {
      color: $DimGray;
      margin: 0 0 15px;
      font-weight: 600;
      font-size: 1.6rem;
      font-family: $manrope;
    }

    h4 {
      margin: 0;
      color: $black;
      font-weight: 600;
      font-size: 1.6rem;
      font-family: $manrope;
      width: calc(100% - 60px);

      span {
        display: block;
        color: $DimGray;
        margin: 5px 0 0;
        font-weight: 400;
        font-size: 1.4rem;
        font-family: $manrope;
      }
    }
  }
}
.at-notification {
  .at-maincontent {
    padding: 30px 40px 30px;
  }
  .at-footercontent {
    flex-direction: column;
  }
}
.at-notificationlist {
  li {
    display: flex;
    padding: 10px 0;
    align-items: flex-start;
    .at-switch {
      margin: 5px 20px 0 0;
    }
  }
}
.at-subscription {
  .at-headcontent {
    padding: 30px 40px;
    border-bottom: 1px solid $Whisper;
    h3 {
      margin: 0;
    }
  }
  .at-palnbox {
    display: flex;
    align-items: flex-start;
    label.at-customcheckbox {
      padding: 0;
      width: 20px;
      height: 20px;
      margin: 5px 40px 0 0;
      align-self: flex-start;
    }
    .at-price {
      color: $black;
      font-weight: 600;
      margin-left: auto;
      font-size: 1.6rem;
      font-family: $manrope;
    }
    h4 {
      width: calc(100% - 160px);
    }
  }
  .at-maincontent {
    padding: 25px 40px 50px;

    .at-palnbox {
      margin: 0 0 35px;
    }
  }
  .at-cardinfobox {
    h4 {
      color: $black;
      margin: 0 0 20px;
      font-weight: 600;
      font-size: 1.4rem;
      font-family: $manrope;
    }
    .form-control {
      @include placeholders($DimGraylight2);
    }
  }
  span {
    color: $DimGray;
    font-weight: 400;
    font-size: 1.4rem;
    font-family: $manrope;
  }
}
.at-tablearea {
  .table-responsive {
    box-shadow: none;
    border-radius: 10px;
    background: $White-Smokelight;
  }
  .at-table td,
  .at-table th {
    border: 0;
    font-weight: 500;
    font-size: 1.6rem;
    padding: 10px 30px;
    font-family: $manrope;
  }
  .at-table th {
    color: $DarkGray;
    font-weight: 600;
  }
}
.at-tabcontent .at-channelinfo .form-group {
  .at-tag {
    height: 22px;
    color: $white;
    font-size: 13px;
    min-width: 72px;
    line-height: 22px;
    background: $Grey;
    border-radius: 4px;
    text-align: center;
    display: inline-block;
    font-family: $manrope;
  }
  .green {
    background: $Shamrock;
  }
  .red {
    background: $themecolor;
  }
}
.unsubscribe {
  margin: 0;
  height: 30px;
  font-size: 13px;
  padding: 10px 15px;
  vertical-align: top;
}
.at-channelinfo {
  .at-btn {
    margin-right: 10px;
  }
}
.at-inputwithicon {
  i.at-vcard {
    left: 0;
    right: auto;
    margin-right: 10px;
  }
  span.form-control {
    padding: 15px 30px 15px 40px;
  }
}
/* =============================================
		    Change Password Moadl style
============================================= */
.at-changepasswordmodal {
  position: absolute;
  margin: auto;
  width: 100%;
  top: 20%;

  .modal-dialog {
    max-width: 480px;
    background-color: #0E1121;
    z-index: 1050;
    position: relative;
    border-radius: 10px;
    margin: auto;
  }
  .modal-header {
    background-color: #191D32;
    border: 0;
    padding: 20px;
    display: flex;
    border-radius: 10px 10px 0 0;

    span {
      color: white
    }
  }
  .modal-footer {
    display: flex;
    padding: 20px;
    justify-content: flex-end;
    background: #191D32;
    border-radius: 0 0 10px 10px;
    gap: 15px;
  }
  .at-modalcontentarea {
    h3 {
      color: $black;
      font-weight: 700;
      font-size: 2.2rem;
      text-align: center;
      margin-bottom: 15px;
      font-family: $manrope;
    }
    .at-description {
      margin-bottom: 40px;
    }
    p {
      margin: 0;
      color: $DimGray;
      font-weight: 500;
      line-height: 1.5;
      font-size: 1.6rem;
      text-align: center;
    }
    .at-error {
      color: red;
      display: flex;
    }
    input {
      background: #191D32;
    }
    input::placeholder, input::-webkit-input-placeholder {
      color: #3A4065 !important;
    }

  }
  .at-themefrom {
    width: 100%;
  }
  .from-group {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    label {
      padding-right: 20px;
    }
  }
  .at-btn {
    margin: 0 auto;
  }
}
/* =============================================
		    Video Details page style
============================================= */
.at-videodetails {
  display: flex;

  .at-content {
    padding: 20px 40px;
    @include cardlayout;
    width: calc(100% - 425px);
  }
  .at-videocontent {
    display: flex;
    position: relative;
    flex-direction: column;

    .at-videoinfobox {
      align-items: flex-start;
      margin-bottom: 15px;
    }

    .at-viewsbox {
      font-size: 2rem;
      margin: 0 0 15px;
    }

    .at-likeandrating-area {
      top: 0;
      right: 0;
      margin-left: auto;
      position: static;
      .at-likeunlikestatus {
        display: flex;
      }

      .at-like,
      .at-dislike {
        display: flex;
        margin: 0 10px;
        font-size: 20px;
        color: $DimGray;
        font-weight: 600;
        align-items: center;
        cursor: pointer;
        @include transition;
        font-family: $manrope;

        i {
          font-size: 20px;
          color: $DarkGray;
          margin-right: 5px;
        }

        &:hover {
          i {
            color: $themecolor;
          }
        }
      }

      .at-dislike {
        &:hover {
          i {
            color: #50696d;
          }
        }
      }
      .at-like.active {
        i {
          color: $themecolor;
        }
      }
      .at-dislike.active {
        i {
          color: #50696d;
        }
      }

      .at-videorating {
        display: flex;
        font-weight: 700;
        font-size: 2.4rem;
        margin-left: 10px;
        color: $themecolor;
        align-items: center;
        font-family: $manrope;

        img,
        i {
          width: 23px;
          margin-right: 5px;
        }
      }
    }
  }
  .at-progressarea {
    // display: flex;
    display: none;
    .at-progressbar {
      width: 195px;
      margin-top: 10px;
      margin-right: 20px;
      .progress {
        height: 5px;
        background: $black;
        .progress-bar {
          background: $themecolor;
        }
      }
    }
  }
}

.at-videoplay {
  width: 100%;
  overflow: hidden;
  // margin-bottom: 30px;
  @include bd-radius(10px);
  video {
    width: 100%;
    height: auto;
    // max-height: 660px;
  }
}
.rt-playerdata {
  padding: 10px 0 !important;
  span {
    padding: 0 !important;
  }
}
.at-vd-infoandcomment {
  padding-right: 400px;

  .at-userboxarea {
    margin-bottom: 15px;

    .at-userbox {
      width: auto;
      height: auto;
      min-height: auto;
      align-items: flex-start;
      flex-direction: row;
      vertical-align: top;
      display: inline-block;

      .at-themeimage {
        width: 80px;
        height: 80px;
        line-height: 80px;
        margin-right: 15px;
      }
    }

    .at-usercontent {
      margin-right: 30px;
      vertical-align: top;
      display: inline-block;
    }
    .at-btnarea {
      display: inline-block;
    }

    .at-followbtn {
      height: 40px;
      padding: 0 10px;
      line-height: 40px;
      font-weight: 900;
      font-size: 1.6rem;
      margin-right: 15px;
      color: $Ultramarine;
      font-family: $manrope;
      background: transparent;
      @include bd-radius(6px);
      text-transform: uppercase;
      border: 1px solid $Ultramarine;

      span {
        margin-left: 20px;
        color: $Ultramarine;
        text-transform: uppercase;
      }
      i {
        vertical-align: middle;
        background: $Ultramarine;
      }
    }
    .at-suscribirsebtn {
      height: 40px;
      padding: 0 10px;
      font-weight: 900;
      font-size: 1.6rem;
      color: $themecolor;
      font-family: $manrope;
      background: transparent;
      @include bd-radius(6px);
      text-transform: uppercase;
      border: 1px solid $themecolor;

      span {
        margin-left: 20px;
      }
      i {
        vertical-align: middle;
      }
    }
  }

  .at-description {
    padding-bottom: 25px;
    border-bottom: 1px solid $DimGraylight;
    .at-textbox {
      max-height: 50px;
      overflow: hidden;
      margin-bottom: 20px;
    }

    p {
      color: $black;
      font-weight: 500;
      font-size: 1.8rem;
      font-family: $manrope;
      word-break: break-word;
      white-space: pre-line;

      span {
        background: transparent !important;
      }

      a {
        color: $themecolor !important;
        background: transparent !important;
      }
    }
    .at-moredetails {
      border: 0;
      outline: none;
      color: white;
      font-weight: 700;
      font-size: 1.8rem;
      font-family: $manrope;
      background: transparent;
      text-transform: uppercase;

      i {
        margin-left: 10px;
        @include transition;
        -moz-transform: rotate(0);
        -webkit-transform: rotate(0);
        -o-transform: rotate(0);
        -ms-transform: rotate(0);
        transform: rotate(0);
      }
    }
  }
  .at-description-expand {
    .at-textbox {
      max-height: 100%;
    }
    .at-moredetails {
      i {
        -moz-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }
  }
}

.at-commentsarea {
  padding: 25px 400px 25px 0;

  .at-head {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;

    h3 {
      color: $white;
      font-weight: 600;
      font-size: 1.8rem;
      margin: 0 50px 0 0;
      font-family: $manrope;
    }
    span {
      color: $white;
      font-weight: 600;
      font-size: 1.8rem;
      font-family: $manrope;
      display: flex;
      align-items: center;
      text-transform: uppercase;

      i {
        margin-right: 5px;
        font-size: 1.5rem;
      }
    }
  }
  .at-dropdown .at-dropdowncontent {
    width: auto;
    .at-dropdownlist {
      padding: 5px 0;
    }
    .dropdown-item {
      color: $black;
      padding: 10px 15px;
      font-size: 1.4rem;
      border-bottom: 1px solid $DimGraylight;
      &:last-child {
        border: 0;
      }
      &:focus,
      &:active {
        color: $black;
      }
    }
  }
  .at-commentsendbox {
    .at-themefrom {
      display: flex;
      flex-direction: column;
      width: calc(100% - 70px);
      .at-btnarea {
        .at-cancelbtn {
          margin-left: auto;
          margin-right: 10px;
        }
      }
    }
    .at-commenttextaddstardust {
      display: flex;
    }
    .at-commenttext {
      margin-right: 20px;
      width: calc(100% - 207px);

      .form-control {
        padding-left: 0;
        border-radius: 0;
        background: transparent;
        border-bottom: 1px solid $DimGraylight;
      }
    }
  }
}
.at-commentbox {
  .at-commentcontent {
    width: calc(100% - 80px);

    .at-title {
      display: flex;
      margin-bottom: 6px;
      align-items: center;

      h3 {
        margin: 0;
        color: $newDimGray;
        font-weight: 600;
        font-size: 14px;
        font-family: $manrope;
        text-transform: capitalize;
      }
      .at-stardust {
        display: flex;
        font-weight: 700;
        font-size: 1.5rem;
        margin-left: 20px;
        color: $themecolor;
        align-items: center;

        font-family: var(--primary-font);
        font-weight: 500;
        -webkit-font-smoothing: antialiased;

        i {
          margin: 0 3px;
          font-size: 1.2rem;
          color: $themecolor;
        }

        img {
          width: 20px;
          margin-right: 3px;
        }

        i.icon-ratingstars {
          font-size: 2rem;
        }
      }
      .at-time {
        color: #3A4065;
        font-weight: 500;
        font-size: 1.4rem;
        margin-left: 10px;
        font-family: $manrope;
      }
    }

    .at-title-video-owner {
      display: flex;
      margin-bottom: 6px;
      align-items: center;

      h3 {
        margin: 0;
        background-color: #3A4065;
        // background-clip: text; /* Clip the background to the text */
        // -webkit-background-clip: text; /* For Safari */
        color: $newDimGray; /* Make the text color transparent */
        -webkit-text-fill-color: $newDimGray;
        font-weight: 600;
        font-size: 14px;
        display: flex;
        align-items: center;
        font-family: $manrope;
        text-transform: capitalize;
        padding: 5px 20px;
        border-radius: 50px;
        box-shadow: inset 1px 1px 1px 0px #ff5093, inset 0 0 0 2px #9A37e7;
      }
      
      .at-time {
        color: #3A4065;
        font-weight: 500;
        font-size: 1.4rem;
        margin-left: 10px;
        font-family: $manrope;
      }
    }
    
    .at-title-stardusts {
      display: flex;
      margin-bottom: 6px;
      align-items: center;

      h3 {
        margin: 0;
        background-image: linear-gradient(to right, #FF5093, #9A37E7); /* Define the linear gradient */
        background-clip: text; /* Clip the background to the text */
        -webkit-background-clip: text; /* For Safari */
        color: transparent; /* Make the text color transparent */
        -webkit-text-fill-color: transparent;
        font-weight: 600;
        font-size: 14px;
        font-family: $manrope;
        text-transform: capitalize;
      }

      .at-stardust {
        display: flex;
        font-weight: 700;
        font-size: 14px;
        background-image: linear-gradient(to right, #FF5093, #9A37E7); /* Define the linear gradient */
        background-clip: text; /* Clip the background to the text */
        -webkit-background-clip: text; /* For Safari */
        color: transparent; /* Make the text color transparent */
        -webkit-text-fill-color: transparent;
        align-items: center;
        padding: 2px 15px;

        font-family: var(--primary-font);
        font-weight: 500;
        -webkit-font-smoothing: antialiased;

        i {
          margin: 0 3px;
          font-size: 1.2rem;
          color: $themecolor;
        }

        img {
          width: 20px;
          margin-right: 3px;
        }

        i.icon-ratingstars {
          font-size: 2rem;
        }
      }
      .at-time {
        color: #3A4065;
        font-weight: 500;
        font-size: 1.4rem;
        margin-left: 10px;
        font-family: $manrope;
      }
    }

    .at-loadmore {

      button {
        color: $DimGray;
        transition: color 0.2s;
        font-family: $manrope;
        font-size: 1.3rem;

        .fa {

          margin-left: 7px;
          margin-right: 5px;

        }

      }

      button:hover {

        color: $black;

      }

    }

    .at-description {
      margin-bottom: 10px;
      .at-comment-description {
        margin: 0;
        color: $newDimGray;
        white-space: pre-line;
        font-size: 1.5rem;
        text-transform: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;

      }
      .close {
        
        -webkit-line-clamp: 4;
        
      }

    }
    .at-actionboxarea {
      display: flex;
      flex-direction: column;
    }
    .at-actionbox {
      display: flex;
      .at-deletbtn {
        font-size: 13px;
        margin-right: 10px;
        color: $themecolor;
        font-family: var(--primary-font);
        cursor: pointer;
      }
      
      .at-replybtn {
        color: white;
      }

      .at-like {
        color: $DarkGray;
        font-size: 1.6rem;
        font-weight: 700;
        margin-right: 20px;
        @include transition;
        font-family: $manrope;
        cursor: pointer;

        &:hover {
          color: $themecolor;
        }
      }
      .active {
        color: $themecolor;
      }

      .at-responderbtn {
        border: 0;
        outline: none;
        color: $DimGray;
        font-weight: 700;
        font-size: 1.6rem;
        font-family: $manrope;
        background: transparent;
        text-transform: uppercase;
      }
    }
    .at-replycomment {
      margin-top: 0px;

      .at-commenttext {
        .form-control {
          padding-left: 0;
          border-radius: 0;
          background: transparent;
          border-bottom: 1px solid rgba(110, 110, 110, 0.3);
        }
      }
      .at-cancelbtn {
        margin-left: auto;
        margin-right: 10px;
        background: #6e6e6e;
      }
    }
  }
}
.at-replycommentbox {
  padding-left: 70px;
  .at-themeimage {
    width: 35px;
    height: 35px;
    line-height: 35px;
  }
}
.at-viewreplybtn {
  font-size: 13px;
  margin-right: auto;
  color: #ff0961;
  display: inline-block;
  font-family: var(--primary-font);
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
}

.at-sidebar {
  width: 400px;
  margin-left: 25px;
  padding: 20px 35px;
  @include cardlayout;

  .at-addbox {
    width: 100%;
    margin-bottom: 20px;
  }

  .at-stardustlist-box {
    background: #0E1121;
    margin-bottom: 25px;

    .at-title {
      display: flex;
      padding: 10px 0;
      background: $black;
      text-align: center;
      align-items: center;
      justify-content: center;
      border-radius: 10px 10px 0 0;

      h3 {
        margin: 0;
        color: $white;
        font-weight: 700;
        font-size: 1.7rem;
        font-family: $manrope;
        text-transform: uppercase;

        i {
          margin: 0 10px;
          font-size: 3rem;
          color: $themecolor;
          display: inline-block;
          vertical-align: middle;
        }
      }
    }

    .at-stardustlist {
      padding: 15px 10px 15px 20px;

      li {
        display: flex;
        font-weight: 600;
        font-size: 1.6rem;
        margin-bottom: 5px;
        color: $themecolor;
        align-items: center;
        text-transform: capitalize;

        i {
          font-size: 2rem;
          margin-right: 5px;
        }

        // img {
        //   width: 20px;
        //   margin-right: 5px;
        // }

        span {
          margin-left: auto;
          text-align: right;
          // width: calc(100% - 47%);
          i {
            margin: 0 5px;
            font-size: 1.5rem;
          }
        }
      }
      li.at-yello {
        color: $LaRioja;
      }
      li.at-black {
        color: $Charcoal;
      }
      li.at-glod {
        color: $Mandalay;
      }
      li.at-gray {
        color: $Grey;
      }
    }
  }
  .at-video {
    width: 100%;
    // height: 180px;
    position: relative;
    margin-bottom: 15px;
    aspect-ratio: 16 / 9;
  }
}
.at-videodetails {
  .at-sidebar {
    .at-viewsbox {
      margin-left: auto;
      font-size: 1.4rem;
    }
  }
}
.carousel-container {
  width: 100%;
}
.at-stardustmodalfrom {
  label {
    color: $black;
    font-weight: 600;
    font-size: 1.6rem;
    margin-bottom: 20px;
    font-family: $manrope;
  }
  .form-control {
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 160px);
  }
  .at-btn {
    margin-left: 10px;
    display: inline-block;
    vertical-align: middle;
  }
}
.modal-open {
  overflow: inherit;
}
/* =============================================
		    Buy Stardust page style
============================================= */
.at-buystardust {
  padding: 35px 20rem;
}
.at-stardust-imagearea {
  width: 100%;
  display: flex;
  margin-bottom: 35px;
  justify-content: center;
}
.at-buystardust {
  .at-contentbox {
    display: flex;
    min-height: 100%;
    padding: 50px 80px;
    text-align: center;
    align-items: center;
    flex-direction: column;

    h2 {
      color: $black;
      font-size: 4rem;
      font-weight: 900;
      margin-bottom: 15px;
      font-family: $manrope;
      text-transform: capitalize;
    }

    h3 {
      color: $black;
      margin: 0 0 30px;
      font-weight: 700;
      font-size: 2.8rem;
      font-family: $manrope;
    }

    p {
      margin: 0;
      color: $black;
      font-size: 2rem;
      line-height: 1.6;
      font-weight: 500;
      font-family: $manrope;
      flex-direction: c;
    }
  }
  .at-stardustlistbox {
    padding: 30px;
  }
  .at-stardustlist {
    // width: 650px;
    display: flex;
    margin: 0;
    flex-direction: column;
    gap: 15px;
    li {
      display: flex;
      padding: 20px;
      align-items: center;
      background: #191D32;
      border-radius: 5px;
      img {
        width: 40px;
        margin-right: 20px;
      }
      span {
        color: #E7F3F3;
        font-weight: 700;
      }
      i {
        font-size: 4rem;
        color: #E7F3F3;
        margin-right: 20px;
      }
      i.icon-shining-star {
        font-size: 3rem;
      }
      i.icon-ratingstar i {
        margin: 0;
        font-style: normal;
      }
      .at-right {
        display: flex;
        margin-left: auto;
        position: relative;
        align-items: center;
        del {
          left: -50px;
          color: $black;
          font-size: 16px;
          margin-right: 5px;
          position: absolute;
          text-transform: capitalize;
        }
        span {
          right: -130px;
          font-size: 16px;
          font-weight: 600;
          position: absolute;
        }
      }
      .at-pricebtn {
        height: 40px;
        padding: 0 20px;
        min-width: 100px;
        min-width: 115px;
        font-weight: 700;
        font-size: 1.8rem;
        line-height: 40px;
        margin-left: auto;
        margin-right: 5px;
        font-family: $manrope;
      }
    }
    li.at-glod {
      i {
        color: $Mandalay;
      }
    }
    li.at-gray {
      i {
        color: $Grey;
      }
    }
    li.at-yello {
      i {
        color: $LaRioja;
      }
    }
    li.at-themecolor {
      i {
        color: $themecolor;
      }
    }
  }
}
/* =============================================
        Terms of service page style
============================================= */
.at-termsofservice {
  padding: 35px 20rem;

  .at-contentbox {
    min-height: 100%;
    padding: 50px 80px;

    h2 {
      color: $black;
      font-size: 3rem;
      font-weight: 700;
      margin-bottom: 20px;
      font-family: $manrope;
      text-transform: capitalize;
    }

    p {
      color: $DimGray;
      margin: 0 0 20px;
      line-height: 1.6;
      font-weight: 500;
      font-size: 1.8rem;
      font-family: $manrope;
    }
  }
}
/* =============================================
		    Earning page style
============================================= */
.at-earningstats {
  margin: 0 0 60px;
}
.at-earnings {
  .at-contentbox {
    padding: 30px 60px;
  }
}
.at-subheading {
  margin-bottom: 20px;
  h3 {
    margin: 0;
    color: $black;
    font-size: 2.2rem;
    font-weight: 700;
    font-family: $manrope;
  }
}
.at-earningarea {
  margin: 0 0 30px;
  padding: 50px 40px;
  @include cardlayout;
}
.at-earningbox {
  padding: 0 20px;
  text-align: center;
  position: relative;
  &::after {
    @include after-before();
    width: 1px;
    left: -15px;
    height: 100%;
    background: $Gainsboro;
  }
  h4 {
    color: $black;
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 5px;
    font-family: $manrope;
  }
  span {
    display: block;
    color: $DimGray;
    font-weight: 600;
    font-size: 1.6rem;
    font-family: $manrope;
    text-transform: capitalize;
  }
}
.at-earningbox.after-0 {
  &::after {
    display: none;
  }
}
.at-earningstats {
  .at-themeform {
    label {
      display: block;
      color: $newDimGray;
      margin: 0 0 15px;
      font-weight: 500;
      font-size: 1.4rem;
      font-family: $manrope;
    }
    .form-control {
      width: 300px;
      margin-right: 15px;
      display: inline-block;
      vertical-align: middle;
      @include placeholders($DimGraylight);
    }
    .at-btn {
      display: inline-block;
      vertical-align: middle;
    }
  }
}
.table-responsive {
  @include cardlayout;
}
.at-table {
  margin: 0;
  thead {
    tr {
      th {
        font-weight: 600;
      }
    }
  }
}
/* =============================================
		    Loading page style
============================================= */

.at-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.nb-spinner {
  width: 75px;
  height: 75px;
  margin: 0;
  margin-top: 20px;
  background: transparent;
  border-top: 4px solid rgb(0, 195, 255);
  border-right: 4px solid transparent;
  border-radius: 50%;
  -webkit-animation: 1s spin linear infinite;
  animation: 1s spin linear infinite;
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* =============================================
		    Error page style
============================================= */

.at-error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;

  h1 {
    font-size: 40px;
  }

  .error-content {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-50px);

    p {
      line-height: 40px;
      margin-left: 10px;
      border-left: 1px solid gray;
      padding-left: 10px;
    }
  }
}

/* =============================================
		    Analytics page style
============================================= */
.at-analytics {
  padding: 20px 35px;
}
.at-statisticsarea {
  display: flex;
  margin: 0 -10px 40px;
}
.at-statisticbox {
  width: 20%;
  padding: 0 10px;
}
.at-statisticholder {
  display: flex;
  background: $Snow;
  @include bd-radius(6px);
  padding: 12px 15px 15px 20px;
  border: 1px solid $WhiteSmoke;
  .at-iconbox {
    width: 5rem;
    height: 5rem;
    display: flex;
    text-align: center;
    margin-right: 20px;
    align-items: center;
    @include bd-radius(50%);
    justify-content: center;
    background: $TickleMePink;
    i {
      color: $white;
      font-size: 2.7rem;
    }
  }
  .at-title {
    h3 {
      margin: 0;
      color: $black;
      font-weight: 700;
      font-size: 2.8rem;
      font-family: $manrope;
    }
    span {
      display: block;
      color: $DimGray;
      font-weight: 600;
      font-size: 1.5rem;
      font-family: $manrope;
      text-transform: capitalize;
    }
  }
  .at-optionandvalue {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    margin-left: auto;
    i {
      color: $DimGray;
      font-size: 1.6rem;
    }
    .at-percentge {
      color: $Shamrock;
      font-weight: 600;
      font-size: 1.6rem;
      i {
        color: $Shamrock;
        font-size: 1.2rem;
        margin-right: 5px;
      }
    }
    .at-red {
      color: $CoralRed;
      i {
        color: $CoralRed;
      }
    }
  }
}
.at-statisticbox.at-views {
  .at-iconbox {
    background: $Perano;
  }
}
.at-statisticbox.at-estimatedrev {
  .at-iconbox {
    background: $MintGreen;
  }
}
.at-statisticbox.at-followers {
  .at-iconbox {
    background: $ElectricBlue;
  }
}
.at-statisticbox.at-followers {
  .at-iconbox {
    background: $ElectricBlue;
  }
}
.at-statisticbox.at-stardust {
  .at-iconbox {
    background: $Milan;
  }
}
.at-chartbox {
  padding: 0 20px;
  position: relative;
  @include bd-radius(6px);
  border: 1px solid $WhiteSmoke;
  .at-head {
    display: flex;
    padding: 20px 0 0;
    align-items: center;
  }
}
.at-analyticschart {
  // height: 300px;
}
.highcharts-title {
  visibility: hidden;
  opacity: 0;
}
.at-sortarea {
  z-index: 1;
  // width: 280px;
  margin-left: auto;

  .at-themefrom {
    display: flex;
    align-items: center;
    label {
      padding: 0 5px;
      font-weight: 600;
      font-size: 1.2rem;
      font-family: $manrope;
    }
    .form-control {
      height: 100%;
      color: $black;
      font-weight: 600;
      font-size: 1.3rem;
      padding: 10px 12px;
      background: $white;
      font-family: $manrope;
      border: 1px solid $Whisper;
    }
  }
}
.at-infolayout {
  min-height: 402px;
  @include bd-radius(6px);
  border: 1px solid $WhiteSmoke;

  .at-head {
    display: flex;
    background: $Snow;
    padding: 20px 40px;
    align-items: center;

    h3 {
      margin: 0;
      color: $black;
      font-weight: 700;
      font-size: 2.6rem;
      font-family: $manrope;
      text-transform: capitalize;
    }

    .at-sortarea {
      width: 150px;
      position: static;
      margin-left: auto;
    }
  }
  .at-viewerslist {
    padding: 30px 40px;

    li {
      display: flex;
      color: $black;
      padding: 20px 0;
      font-weight: 700;
      font-size: 1.6rem;
      text-transform: capitalize;
      em {
        margin-left: auto;
        font-style: normal;
      }
    }
  }
}
.at-barchart {
  margin-top: 40px;
  padding: 40px 20px;
}
.at-demographics {
  margin-top: 40px;
}
.at-error {
  color: red;
  text-align: left;
  font-family: var(--primary-font);
  font-size: 13px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  margin-top: 3px;
}

.range-picker-input {
  border-radius: 6px;
}
.range-picker-input__icon {
  display: none;
}
input.picker-input__text {
  background: transparent;
  @include placeholders($DimGraylight2);
}
.picker__container {
  left: auto !important;
  right: 85px !important;
}
.calendar__head {
  padding: 10px 20px;
  background: $themecolor;
}
.at-pageheading h2.calendar__head--title {
  color: $white;
  font-size: 2rem;
}
.calendar__body--table .calendar__day,
.calendar__body--table thead tr th,
.calendar__body--table tbody tr td {
  border: 0;
  padding: 12px 0;
  text-align: center;
}
.Toastify__toast-body {
  font-size: 1.6rem;
}
.height100vh {
  height: 100vh !important;
}

.at-channelvideo {
  .at-videobox {
    // width: 335px;
  }
  .react-multi-carousel-item {
    .at-videobox {
      width: 100%;
    }
  }
}
.select__control--is-disabled {
  background: rgba(0, 0, 0, 0.08) !important;
}
.at-textarea {
  overflow: hidden;
}
//-----------auto suggest---------------------------
.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 240px;
  height: 30px;
  padding: 10px 20px;
  font-family: $manrope;
  font-weight: 400;
  font-size: 16px;
  border-radius: 10px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  border-radius: 8px;
  border: 1px solid #f0f0f0;
  top: 47px;
  width: 100%;
  z-index: 11;
  display: block;
  font-size: 16px;
  font-weight: 400;
  position: absolute;
  background-color: #fff;
  font-family: $manrope;
  padding: 10px 0px;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  font-family: var(--primary-font);
  font-weight: 300;
  font-size: 15px;
  padding: 8px 20px;
  letter-spacing: 0.3px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: transparent;
}
//-----------auto suggest---------------------------

.shine {
  background: #f6f7f8;
  background-image: linear-gradient(
    to right,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 104px;
  display: inline-block;
  position: relative;

  -webkit-animation-duration: 2s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
}

.shimmering {
  display: inline-flex;
  flex-direction: column;
  vertical-align: top;
  overflow: hidden;
}

.title {
  height: 15px;
  margin-top: 10px;
  width: 100%;
}

.lines {
  height: 10px;
  margin-top: 10px;
  width: 150px;
}

.photo {
  display: block !important;
  width: 325px;
  height: 100px;
  margin-top: 15px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}
.alert {
  font-size: 14px;
}
.at-nfiniteloader.at-videoloader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.at-nfiniteloader.at-videoloader-comments {
  display: inline;
  flex-direction: column;
  align-items: center;
}

.at-videoloader .spinner-border {
  width: 5rem;
  height: 5rem;
  margin: 0 auto;
  border-color: $themecolor;
  border-right-color: transparent;
}
.at-videoloader .at-videobox .spinner-border {
  width: 2rem;
  height: 2rem;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
}
.at-addcontent {
  height: 100%;
  display: flex;
  max-height: 180px;
  border-radius: 12px;
  margin-bottom: 15px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
  span {
    color: $white;
    display: block;
    font-size: 2rem;
    text-align: center;
  }
}
.min-width {
  min-width: 117px;
}
.at-replyto {
  display: flex;
  justify-content: space-between;
  margin-top: 3%;
}
textarea.at-textHeight {
  height: 175px;
}
.margin {
  margin-right: 8px;
}

// .comment-sort{
//     width: 160px !important;
// }
.at-signup,
.at-uploadform {
  .picker {
    width: 100%;
  }
  .picker-input {
    width: 100%;
  }
  input.picker-input__text {
    width: 100%;
    padding-left: 30px !important;
    background: rgba(239, 239, 239, 0.5);
  }
  .calendar__day:hover:not(.calendar__day--disabled),
  .calendar__day--start,
  .calendar__day--end,
  .calendar__day--selected {
    color: $white;
    background: $themecolor;
  }
  .picker__container {
    right: auto !important;
  }
  .select__single-value,
  .css-1uccc91-singleValue,
  .css-b8ldur-Input,
  .css-1g6gooi,
  .css-1wa3eu0-placeholder {
    margin: 0 !important;
  }
  .select__value-container,
  .css-g1d714-ValueContainer {
    padding: 0 30px !important;
  }
}
.rc-anchor-light {
  background: $white !important;
}
.rc-anchor,
.rc-anchor-dark {
  background: #f9f9f9 !important;
  color: #000 !important;
}
.Toastify__toast--error {
  background: $themecolor !important;
}
.Toastify__toast--success {
  background: #51a351 !important;
}
@import "scss/_responsive";

// next

.at-wrapper {
  overflow: visible;
}
.at-header {
  height: 75px;
  background-color: rgba(14, 17, 33, 1);
  backdrop-filter: blur(16px);
  position: sticky;
  z-index: 10;
  top: 0;
  // box-shadow: 0 0 40px rgba(255, 255, 255, 0.8);
}
.at-logo {
  align-self: center;
}
.swiper-container {
  width: 100%;
}
.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 100%;
  height: auto;
}
.at-bannervideobox {
  padding-left: 60px;
  padding-right: 60px;
}
.swiper-slide img {
  display: block;
  width: 100%;
}
.vdspr {
  position: relative;
  background-color: #000;
}
.vdspr:before {
  content: "";
  display: block;
  height: 100%;
}
.at-banner {
  margin-bottom: 15px;
  margin-top: 0;
}
.at-main {
  margin-top: 0px;
}
.at-rightarea .at-loginbtn {
  height: 45px;
  font-weight: 700;
  margin: 0;
}
.at-recommendedinfo .at-infobtnbox {
  padding: 0;
}
.at-recommendedinfo {
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr;
}
.at-banner .at-banner-area {
  padding: 15px;
}
.at-infobtnbox {
  width: 100%;
}
.at-banner .at-banner-area > * {
  margin: 0;
  padding: 0;
}
.at-recommendedinfo .at-infobtn {
  min-width: 0;
}
.at-banner-area .at-addbox {
  width: auto;
}
.at-banner-area .at-addbox img {
  height: auto;
}
.dvrzn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20px;
}
.dvrzn p {
  font-size: 18px;
}
p.mltx {
  font-weight: bold;
  font-size: 14px;
}
.mltx:after {
  content: "@stardeos.com";
}
.wrtus {
  margin-top: 15px;
}
.stbxcnt {
  display: flex;
  flex-direction: column;
}
.stbxcnt .sdbr {
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(16px);
  order: 999;
}
.stbxcnt main {
  flex-grow: 1;
}
.sdbr-ttl {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 9px 27px;
  margin-top: 9px;
}
.sdbr-mn {
  font-size: 16px;
  padding: 9px;
}
.sdbr-mn a {
  padding: 5px 18px;
  color: #999;
  border-radius: 8px;
}
.sdbr-mn a:hover {
  color: #000;
}
.at-sidebar,
.at-videodetails .at-content {
  padding: 20px;
}
.at-sidebar {
  margin-left: 20px;
}
.rt-playerdata {
  padding-left: 20px;
  padding-right: 20px;
}
.pgcn {
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
}
.pgttl {
  font-weight: bold;
  font-size: 2.5rem;
}

.pgtext1 {
  font-size: 1.5rem;
}

@media (min-width: 992px) {
  .at-banner .at-banner-area {
    grid-template-columns: 220px calc(100% - 440px - 30px) 220px;
  }
}
@media (min-width: 1200px) {
  .stbxcnt {
    flex-direction: row;
  }
  .stbxcnt .sdbr {
    flex: 0 0 220px;
    height: calc(100vh - 75px);
    overflow: auto;
    position: sticky;
    top: 70px;
    order: -1;
  }
}
@media (min-width: 1440px) {
  .swiper-slide {
    width: 500px;
  }
  .swiper-slide .vdspr {
    transform: scale(0.8);
    opacity: 0.8;
  }
  .swiper-slide.swiper-slide-active .vdspr {
    transform: scale(1);
    opacity: 1;
  }
}
@media (min-width: 1600px) {
  .at-banner .at-banner-area {
    grid-template-columns: 300px calc(100% - 600px - 30px) 300px;
  }
}

.button {
  flex: 1 1 auto;
  height: 50px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.9s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  font-weight: 700;
  text-shadow: 1px 1px 2px #0000001a;
  position: relative;
  // text-shadow: 1px 1px 1px #8080803b;

  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  -webkit-font-smoothing: antialiased;
  cursor: pointer;

  @media screen and (max-width: 350px) {
    font-size: 12px;
  }

  &.primary {
    background-color: $newMainBlack;
    box-shadow: inset 1px 1px 1px 0px #ff5093, inset 0 0 0 2px #9A37e7;
  }

  &.secondary {
    background-image: linear-gradient(
      to right,
      #ff669c 0%,
      #c8befd 51%,
      #ff669c 100%
    );
  }

  &.third {
    background-color: $newMainBlack;
    box-shadow: inset 1px 1px 1px 0px #ff5093, inset 0 0 0 2px #9A37e7;
    border-radius: 10px !important;
  }

  &.fourth {
    background-color: $fourthColor;
    border: 1.5px solid #3A4065;
  }

  &.cancel {
    background-image: linear-gradient(
      to right,
      #8c8c8c 0%,
      hsl(0deg 0% 81%) 51%,
      #8c8c8c 100%
    );
  }

  &.delete-button {
    margin-left: 20px;
  }

  &:hover {
    background-position: right center;
  }
}

@-webkit-keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@-moz-keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.link {
  &.primary {
    color: #62A4D6;

    &:hover {
      color: #7cd0fd;
    }
  }
}

.fade {
  transition: opacity 0.15s linear;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #0000006e;
}

.MuiInput-underline:before,
.MuiInput-underline:after {
  border: none !important;
  border-bottom: none;
}

.MuiFormControl-root {
  width: 100%;
}

.MuiIconButton-root {
  margin: 0 !important;
  margin-top: 0 !important;
}

.MuiPickersToolbar-toolbar,
.MuiPickersDay-daySelected {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ceb8ff),
    color-stop(51%, #a1dffd),
    to(#d9c7ff)
  );
  background-image: -webkit-linear-gradient(
    left,
    #ceb8ff 0%,
    #a1dffd 51%,
    #d9c7ff 100%
  );
  background-image: linear-gradient(
    to right,
    #ceb8ff 0%,
    #a1dffd 51%,
    #d9c7ff 100%
  );
}

input.MuiInputBase-input::placeholder {
  color: #b6b6b6 !important;
  opacity: 1;
}

.MuiPickersDay-day,
.MuiPickersCalendarHeader-dayLabel {
  font-size: 1.2em !important;
}

.ad-container.open {
  background: linear-gradient(45deg, #FF5093, #9A37E7) !important;
  color: white !important;
}
.stardust-ads-container * {
  color: white !important;
}
button.close-button.btn.btn-primary {
  display: none !important;
}
.video-container button.close-button.btn.btn-primary {
  display: block !important;
}

.ad-sponsors * {
  color: white !important;
}

.sbdr {
  overflow-x: hidden !important;
}

.adsense-ad, .ad-container {
  cursor: pointer !important;
}

.background-gradient {
  background: linear-gradient(45deg, rgb(173, 129, 255), rgb(141, 211, 255)) !important;
}

aside.sdbr {
  // overflow-y: hidden !important;
}

.react-stacked-center-carousel-slide-0 {
  border-radius: 8px;
  box-shadow: inset 1px 1px 1px 0px #ff5093, inset 0 0 0 2px #9A37e7;
  border-image: linear-gradient(to right, #ff5093, #9a37e7);
  border-image-slice: 1;
  border-width: 3px;
  padding: 2px;
}

.player-poster[data-poster] {
  background-color: #1F243E !important;
}

.at-channel-btngroup {
  gap: 5px;
  background-color: #020410;
  border-radius: 24px;
  padding: 4px;
  display: flex;
}

.at-channel-btn input[type="radio"] {
  display: none;
}

.at-channel-btn-active input[type="radio"] {
  display: none;
}

.at-channel-btn {
  color: #E7F3F3;
  font-size: 16px;
  padding: 10px;
  margin: 0px;
  display: flex;
  align-items: center;

  i {
    display: flex;
  }
}

.at-channel-btn input[type="radio"]:checked + label {
  color: #E7F3F3;
  font-size: 16px;
  border-radius: 24px;
  background-color: #1F243E;
  padding: 10px;
  margin: 0px;
  display: flex;
  align-items: center;
}

.at-channel-btn-active {
  color: #E7F3F3;
  font-size: 16px;
  border-radius: 24px;
  background-color: #1F243E;
  padding: 10px;
  margin: 0px;
  display: flex;
  align-items: center;
  
  i {
    display: flex;
  }
}
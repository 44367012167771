/* =============================================
			Colors
============================================= */
$black: #000;
$white: #fff;
$newMainBlack:#020410;
$newMainBorder: #191D32;
$newDimGray: #E7F3F3;
$fourthColor: #1F243E;
$cardLayoutColor1:#0E1121;
$DimGray: #6e6e6e;
$themecolor: hsl(322deg 90% 78%);
$White-Smoke: #f7f7f7;
$White-Smokelight: rgba(239, 239, 239, 0.5);
$Gainsboro: #e2e2e2;
$Whisper: #eaeaea;
$DimGraylight: #3A4065;
$DimGraylight2: rgba(110, 110, 110, 0.5);
$blacklight: rgba(0, 0, 0, 0.7);
$Grey: #7c7c7c;
$DarkGray: #b0b0b0;
$WhiteSmoke: #efefef;
$Whisper: #eeeeee;
$Snow: #fafafa;
$LightGrey: #d3d3d3;
$Ultramarine: #161285;
$Java: #178a8e;
$LaRioja: #c6bc09;
$Charcoal: #444444;
$Mandalay: #b18427;
$TickleMePink: #ff7aaa;
$Shamrock: #31d784;
$CoralRed: #ff4141;
$Perano: #b5aef6;
$MintGreen: #98fe87;
$ElectricBlue: #93f9f1;
$Milan: #f9f58d;
$Solitude: #e3e7eb;

$manrope: "Manrope", sans-serif;

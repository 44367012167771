html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.picker {
  width: 100%;
}

.picker-input {
  width: 100%;
}

input.picker-input__text,
.at-uploadform input.picker-input__text {
  width: 100%;
  height: 45px;
  border-radius: 4px;
  background-color: #f7f9fc;
  border: 1px solid #e4e9f1;
  padding: 0px 10px;
  letter-spacing: 0.3px;
  font-size: 13px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
